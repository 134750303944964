angular.module("app").component("blogComponent", {
  templateUrl: "app/components/blog/blog.tmpl.html",
  controller: function ($http, $scope, appConfig) {
    const vm = this;
    const perPage = 10;

    $scope.numPage = 1;
    vm.blogs = [];
    vm.categoryList = [];
    vm.archiveList = [];
    $scope.notification = { loading: "Loading...", notFound: "" };
    $scope.resSearch = true;
    $scope.valueSearchByDate = "";
    $scope.maxSize = 5;
    $scope.bigCurrentPage = 1;

    const sortArchiveDates = function (unsortedArchive) {
      const monthsOfYear = [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
      ];
      const archive = [...unsortedArchive];

      archive.sort(function (a, b) {
        const [monthA, yearA] = a.split(" ");
        const [monthB, yearB] = b.split(" ");

        if (yearA !== yearB) {
          return +yearB - +yearA;
        }

        return (
          monthsOfYear.indexOf(monthB.toLowerCase()) -
          monthsOfYear.indexOf(monthA.toLowerCase())
        );
      });
      return archive;
    };

    $scope.init = () => {
      let currentUrl = `${appConfig.dashboardServiceUrl}api/posts.json?page=${$scope.numPage}&per_page=${perPage}`;

      $http
        .get(currentUrl)
        .then((res) => {
          if (res && res.data) {
            if (!vm.categoryList.length && !vm.archiveList.length) {
              vm.categoryList = [...res.data.categories];
              vm.archiveList = ["All", ...sortArchiveDates(res.data.dates)];
            }
            vm.blogs = [...res.data.posts];
            $scope.bigTotalItems = res.data.paginate.total_entries;
            vm.total_pages = res.data.paginate.total_pages;
            $scope.notification.loading = "";
          } else {
            $scope.resSearch = false;
          }
        })
        .catch((err) => {
          if (err.status === 404) {
            $scope.resSearch = false;
          }
        });
    };

    $scope.init();

    $scope.showMoreItems = function (numPage) {
      if (numPage === undefined) {
        numPage = $scope.numPage;
      }

      if ($scope.searchParam) {
        $http
          .get(
            `${appConfig.dashboardServiceUrl}api/posts.json?page=${numPage}&per_page=${perPage}${$scope.searchParam}`
          )
          .then((res) => {
            if (res.data.posts) {
              $scope.notification = { loading: "", notFound: "" };
              vm.blogs = [...res.data.posts];
              $scope.bigTotalItems = res.data.paginate.total_entries;
              vm.total_pages = res.data.paginate.total_pages;
            } else {
              $scope.notification.notFound = "Not Found";
              $scope.resSearch = false;
            }
          })
          .catch((err) => {
            if (err.status === 404) {
              $scope.resSearch = false;
            }
          });
      } else {
        $scope.numPage = numPage;
        $scope.init();
      }
    };

    this.hasMoreReportsToShow = () => {
      return $scope.numPage <= vm.total_pages;
    };

    this.searchOfPost = (searchParam) => {
      $scope.notification = { loading: "Loading...", notFound: " " };
      $scope.numPage = 1;
      vm.blogs = [];

      if (searchParam === "&date=All") {
        searchParam = "";
      }

      $scope.searchParam = searchParam;

      $http
        .get(
          `${appConfig.dashboardServiceUrl}api/posts.json?page=${$scope.numPage}&per_page=${perPage}${searchParam}`
        )
        .then((res) => {
          if (res.data.posts) {
            $scope.notification = { loading: "", notFound: "" };
            vm.blogs = [...res.data.posts];
            vm.total_pages = res.data.paginate.total_pages;
            $scope.bigTotalItems = res.data.paginate.total_entries;
          } else {
            $scope.notification.notFound = "Not Found";
            $scope.resSearch = false;
          }
        })
        .catch((err) => {
          if (err.status === 404) {
            $scope.resSearch = false;
          }
        });
    };
  },
});
angular.module("ui.bootstrap").controller("bootstrapCtrl", function ($scope) {
  $scope.$watch("bigCurrentPage", function () {
    $scope.showMoreItems($scope.bigCurrentPage);
  });
});
