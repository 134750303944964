angular.module('app').directive('drColorsInclude', function () {

    function link() {
		$(document).ready(function(){

            var includeBtn = $('.colors-include__btn'),
             includeList = $('.colors-include__list');
            
            includeBtn.on('click', function () {
                includeBtn.toggleClass('colors-include__btn_active');
                includeList.toggleClass('colors-include__list_active');
            }); 
        });
    }
    
    return {
		restrict: 'E',
        templateUrl: 'app/directives/drColorsInclude/drColorsIncludeView.html',
        link: link
	};
});
