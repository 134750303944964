angular
	.module('app')
	.component('arhiveArtifactComponent', {
		templateUrl: 'app/components/archive-artifact/archive-artifact.tmpl.html',
		controller: function ($scope, $state, $http, serviceItem, ngNotify, appConfig, $stateParams, modalService, $cookies) {
            const vm = this;
            var widthDocument = document.documentElement.clientWidth;
            vm.sliders = null;
            vm.artifact = null;

            vm.checkedCheckboxes = [];
            vm.arhiveArtifact;

            $http.get(appConfig.dashboardServiceUrl + `api/artifacts/${$stateParams.id}.json`)
                .then((res) => {
                    if (res.data) {
                        vm.arhiveArtifact = res.data;
                        $scope.colorNames = res.data.color_names;
                        vm.arhiveArtifact.color_names.forEach((item)=> {
                            return item.showPopup = false;
                        })
                    }
                })
                .catch((err) => {console.log('err', err);});

            this.checkedArchiveCheckbox = function (color) {

                if (vm.checkedCheckboxes.length < 50) {
                    if (color.done) {
                        positionPopup(event);
                        vm.checkedCheckboxes.push(color);
                    } else {
                        vm.checkedCheckboxes.splice(vm.checkedCheckboxes.indexOf(color), 1);
                    }
                }
            };
            $scope.processingCheckbox = function (event) {
                positionPopup(event);
            };

            this.saveMyColors = function () {
                var color_name_ids = {"color_name_ids": vm.checkedCheckboxes.map(function (item) {return item.id;})};

                $http.post('https://colorassociationus.herokuapp.com/api/profile/color_names/add.json',
                    color_name_ids,
                    {
                        headers: {
                            'Authorization': 'Token token=' + $cookies.get('caus_session'),
                            'Accept': 'application/json'
                        }
                    }
                ).then(function (res) {
                    if (res && res.data) {
                        if (res.data.color_names && res.data.profile) {
                            vm.checkedCheckboxes = [];
                            vm.arhiveArtifact.color_names.map((color) => {return color.done = false});
                            ngNotify.set('Your colors have been successfully saved!', {
                                type: 'success',
                                duration: 3000
                            });
                        }
                    }
                }).catch(function (err) {
                    if (err.status === 401) {
                        modalService.showModal(6);
                    }
                });
            };

            this.saveColor = id => {
                var color_name_ids = {'color_name_ids': [id]};

                $http.post('https://colorassociationus.herokuapp.com/api/profile/color_names/add.json',
                    color_name_ids,
                    {
                        headers: {
                            'Authorization': 'Token token=' + $cookies.get('caus_session'),
                            'Accept': 'application/json'
                        }
                    }
                ).then(res => {
                    if (res && res.data) {
                        if (res.data.color_names && res.data.profile) {
                            ngNotify.set('Your color have been successfully saved!', {
                                type: 'success',
                                duration: 3000
                            });
                            vm.arhiveArtifact.color_names.map(el => el.done = false);
                        }
                    }
                }).catch(err => {
                    if (err.status === 401) {
                        modalService.showModal(6);
                    }
                    vm.arhiveArtifact.color_names.map(el => el.done = false);
                });
            };

            function positionPopup(event) {
                var side = widthDocument - event.clientX;

                if (side < 640) {
                    $('.popup').css('right', '120px');
                } else {
                    $('.popup').css('left', '120px');
                }
            }

            this.deleteColor = function (color) {
                vm.checkedCheckboxes.splice(vm.checkedCheckboxes.indexOf(color), 1);
                color.done = false;
            };

            vm.startSliders = () => {
                $(document).ready(function(){
                    $('.slider__main').slick({
                       slidesToShow: 1,
                       slidesToScroll: 1,
                       arrows: true,
                       dots: true,
                       asNavFor: '.slider__carousel',
                       infinite: false
                   });

                   $('.slider__carousel').slick({
                       slidesToShow: 4,
                       slidesToScroll: 1,
                       arrows: true,
                       asNavFor: '.slider__main',
                       focusOnSelect: true,
                       vertical: true,
                       infinite: false,
                       responsive: [
                           {
                             breakpoint: 1200,
                             settings: {
                                 vertical: false
                             }
                           }
                       ]
                   });
               });
            };

			vm.init = function () {
				$http.get(`${appConfig.dashboardServiceUrl}/api/artifacts/${$stateParams.id}.json`)
					.then(function (res) {
						vm.artifact = res.data;
						vm.sliders = res.data.sliders;
						vm.startSliders();
					});
			}
        }
	});
