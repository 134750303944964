angular
	.module('app')
	.component('archiveForecastComponent', {
		templateUrl: 'app/components/archive-forecast/archive-forecast.tmpl.html',
		controller: function ($scope, $sce, $state, $http, serviceItem, ngNotify, appConfig, $stateParams, modalService, $cookies, $location) {
			var vm = this;
			var widthDocument = document.documentElement.clientWidth;
			vm.authorizedUser = false;
			$scope.hiddenModal = true;

			if($cookies.get('caus_session') !== undefined) {
				vm.authorizedUser = true;
			}

			vm.checkedCheckboxes = [];
			vm.archiveForecast;

			$http.get(appConfig.dashboardServiceUrl + `api/archive_forecasts/${$stateParams.id}.json`)
				.then((res) => {
					if (res.data) {
						vm.archiveForecast = res.data;
						$scope.htmlString = $sce.trustAsHtml(vm.archiveForecast.description);
						$scope.colorNames = res.data.color_names;
						vm.archiveForecast.color_names.forEach((item)=> {
							return item.showPopup = false;
						})
					}
				})
				.catch((err) => {console.log('err', err);});

			this.checkedArchiveCheckbox = function (color) {

				if (vm.checkedCheckboxes.length < 50) {
					if (color.done) {
						positionPopup(event);
						vm.checkedCheckboxes.push(color);
					} else {
						vm.checkedCheckboxes.splice(vm.checkedCheckboxes.indexOf(color), 1);
					}
				}
			};

			$scope.goBack = () => {
				history.back();
				return false;
			}

			$scope.processingCheckbox = function (event) {
				positionPopup(event);
			};

			this.saveMyColors = function () {
				var color_name_ids = {"color_name_ids": vm.checkedCheckboxes.map(function (item) {return item.id;})};

				$http.post('https://colorassociationus.herokuapp.com/api/profile/color_names/add.json',
					color_name_ids,
					{
						headers: {
							'Authorization': 'Token token=' + $cookies.get('caus_session'),
							'Accept': 'application/json'
						}
					}
				).then(function (res) {
					if (res && res.data) {
						if (res.data.color_names && res.data.profile) {
							vm.checkedCheckboxes = [];
							vm.archiveForecast.color_names.map((color) => {return color.done = false});
							ngNotify.set('Your colors have been successfully saved!', {
								type: 'success',
								duration: 3000
							});
						}
					}
				}).catch(function (err) {
					if (err.status === 401) {
						modalService.showModal(6);
					}
				});
			};

			this.saveColor = id => {
				var color_name_ids = {'color_name_ids': [id]};

				$http.post('https://colorassociationus.herokuapp.com/api/profile/color_names/add.json',
					color_name_ids,
					{
						headers: {
							'Authorization': 'Token token=' + $cookies.get('caus_session'),
							'Accept': 'application/json'
						}
					}
				).then(res => {
					if (res && res.data) {
						if (res.data.color_names && res.data.profile) {
							ngNotify.set('Your color have been successfully saved!', {
								type: 'success',
								duration: 3000
							});
							vm.archiveForecast.color_names.map(el => el.done = false);
						}
					}
				}).catch(err => {
					if (err.status === 401) {
						modalService.showModal(6);
					}
					vm.archiveForecast.color_names.map(el => el.done = false);
				});
			};

			function positionPopup(event) {
				var side = widthDocument - event.clientX;

				if (side < 640) {
					$('.popup').css('right', '120px');
				} else {
					$('.popup').css('left', '120px');
				}
			};

			this.deleteColor = function (color) {
				vm.checkedCheckboxes.splice(vm.checkedCheckboxes.indexOf(color), 1);
				color.done = false;
			};

			$scope.showImagePopup = function () {
				modalService.showModal(10, {
					image_url: vm.archiveForecast.original_image_url,
					isForecast: true
				});
			}

		}
	});
