angular.module('app').directive('drSelectReports', function ($location) {
	function link($scope) {
		$(document).ready(function () {
			$(document).click(function (event) {
				if ($(event.target).closest('.selectPopularityReports').length === 0) {
					$('.selectPerPage__list-popularity').removeClass('show');
				}
				if ($(event.target).closest('.selectCollectionReports').length === 0) {
					$('.selectPerPage__list-collection').removeClass('show');
				}
				if ($(event.target).closest('.selectCategoryReports').length === 0) {
					$('.selectPerPage__list-category').removeClass('show');
				}
				if ($(event.target).closest('.selectSeasonReports').length === 0) {
					$('.selectPerPage__list-season').removeClass('show');
				}
				if ($(event.target).closest('.selectYearReport').length === 0) {
					$('.selectPerPage__list-year').removeClass('show');
				}

				if ($(event.target).closest('.selectPopularityReports').length) {
					return;
				}
				if ($(event.target).closest('.selectCollectionReports').length) {
					return;
				}
				if ($(event.target).closest('.selectCategoryReports').length) {
					return;
				}
				if ($(event.target).closest('.selectSeasonReports').length) {
					return;
				}
				if ($(event.target).closest('.selectYearReport').length) {
					return;
				}
				$('.selectPerPage__list').removeClass('show');
				event.stopPropagation();
			});
		});

		$scope.current = {
			popularity: 'ALL POPULARITIES',
			collection: 'ALL COLLECTIONS',
			category: $location.search().category || 'ALL CATEGORIES',
			season: $location.search().season || 'ALL SEASONS',
			year: $location.search().year || 'ALL YEARS'
		};

		$scope.$watch('filterList', function () {
			$scope.seasons = $scope.filterList[1];
			$scope.years = $scope.filterList[2].reverse();
			$scope.categories = $scope.filterList[0];

			$scope.seasons.length > 0 ? $scope.seasons.unshift("ALL SEASONS"): $scope.seasons = null;
			$scope.years.length > 0 ? $scope.years.unshift("ALL YEARS") : $scope.years = null;
			$scope.categories.length > 0 ? $scope.categories.unshift("ALL CATEGORIES") : $scope.categories = null;
		});

		$scope.popularities = $scope.filterList[3];
		$scope.collections = $scope.filterList[4];

		$scope.popularities.length > 0 ? $scope.popularities.unshift("ALL POPULARITIES") : $scope.popularities = null;
		$scope.collections.length > 0 ? $scope.collections.unshift("ALL COLLECTIONS") : $scope.collections = null;

		var multiSearch = {};

		$scope.showSelect = function (isSelected) {
			$(`.selectPerPage__list-${isSelected}`).toggleClass('show');
		};

		$scope.passSelectPopularity = function (popularity) {
			$('.selectPerPage__list-popularity').toggleClass('show');
			multiSearch.popularity = popularity;

			if(popularity === "ALL POPULARITIES") {
				if(multiSearch.popularity) delete multiSearch.popularity;
				$scope.filterBySearch(multiSearch);
			} else if (popularity) {
				$scope.filterBySearch(multiSearch);
			}
		};

		$scope.passSelectCollection = function (collection) {
			$('.selectPerPage__list-collection').toggleClass('show');
			multiSearch.collection = collection;

			if(collection === "ALL COLLECTIONS") {
				if(multiSearch.collection) delete multiSearch.collection;
				$scope.filterBySearch(multiSearch);
			} else if (collection) {
				$scope.filterBySearch(multiSearch);
			}
		};

		$scope.passSelectCategory = function (category) {
			$('.selectPerPage__list-category').toggleClass('show');
			multiSearch.category = category;

			if(category === "ALL CATEGORIES") {
				if(multiSearch.category) delete multiSearch.category;
				$scope.filterBySearch(multiSearch);
			} else if (category) {
				$scope.filterBySearch(multiSearch);
			}
		};

		$scope.passSelectSeason = function (season) {
			$('.selectPerPage__list-season').toggleClass('show');
			multiSearch.season = season;

			if(season === "ALL SEASONS") {
				if(multiSearch.season) delete multiSearch.season;
				$scope.filterBySearch(multiSearch);
			} else if (season) {
				$scope.filterBySearch(multiSearch);
			}
		};

		$scope.passSelectYear = function (year) {
			$('.selectPerPage__list-year').toggleClass('show');
			multiSearch.year = year;

			if(year === "ALL YEARS") {
				if(multiSearch.year) delete multiSearch.year;
				$scope.filterBySearch(multiSearch);
			} else if (year) {
				$scope.filterBySearch(multiSearch);
			}
		};
	}

	return {
		restrict: 'E',
		templateUrl: 'app/directives/drSelectorReports/drSelectorReportsView.html',
		link: link,
		scope: {
			filterList: '=',
			filterBySearch: '='
		}
	};
});
