angular.module('app').directive('drCartPaymentInfo', function () {
	function process() {

    }
    return {
		restrict: 'E',
        templateUrl: 'app/directives/drCartPaymentInfo/drCartPaymentInfoView.html',
			process: process
	};
});
