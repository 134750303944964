angular.module("app").config(routesConfig);

function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true);
  $urlRouterProvider.otherwise("/");

  $stateProvider
    .state("press", {
      url: "/press",
      component: "pressComponent",
    })
    .state("redirector", {
      url: "/redirector",
      component: "redirectorComponent",
    })
    .state("/", {
      url: "/",
      component: "landingComponent",
    })
    .state("inquireAboutMembership", {
      url: "/inquire-about-membership",
      component: "inquireAboutMembershipComponent",
    })

    .state("blog", {
      url: "/blog",
      component: "blogComponent",
    })
    .state("instagramPosts", {
      url: "/instagram-posts",
      component: "instagramPosts",
    })
    .state("archiveArtifactById", {
      url: "/archive-artifact/:id",
      component: "arhiveArtifactComponent",
      access: true,
    })
    .state("forecastTrendStoriesById", {
      url: "/forecast-trend-stories/:id",
      component: "forecastTrendStoriesByIdComponent",
      access: true,
    })
    // .state('colorReports', {
    // 	url: '/color-reports',
    // 	component: 'colorReportsComponent'
    // })
    .state("forecast", {
      url: "/forecast/:id",
      component: "forecastComponent",
      access: true,
    })
    .state("contact", {
      url: "/contact",
      component: "contactComponent",
    })
    .state("terms", {
      url: "/terms-us",
      component: "termsComponent",
    })
    // .state('cart', {
    // 	url: '/cart',
    // 	component: 'cartComponent',
    // 	access: true
    // })
    .state("colorNames", {
      url: "/color-names",
      component: "colorNamesComponent",
    })
    .state("standard", {
      url: "/standard/:id",
      component: "standardComponent",
      access: true,
    })
    .state("standards", {
      url: "/standards",
      component: "standardsComponent",
      access: true,
    })
    .state("archiveForecasts", {
      url: "/archive-forecasts",
      component: "archiveForecastsComponent",
      access: true,
    })
    .state("archiveDigs", {
      url: "/archive-digs",
      component: "archiveDigsComponent",
      access: true,
    })
    .state("colorInfographic", {
      url: "/color-infographic",
      component: "colorInfographicComponent",
      access: true,
    })
    .state("privacy", {
      url: "/privacy",
      component: "privacyComponent",
    })
    .state("profile", {
      url: "/profile",
      component: "profileComponent",
      access: true,
    })
    .state("cartProgress", {
      url: "/cart-progress",
      component: "cartProgressComponent",
      access: true,
    })
    .state("login", {
      url: "/login",
      component: "loginComponent",
    })
    .state("cartThank", {
      url: "/cart-thank",
      component: "cartThankComponent",
      access: true,
    })
    .state("archiveForecast", {
      url: "/archive-forecast/:id",
      component: "archiveForecastComponent",
      access: true,
    })
    .state("myColors", {
      url: "/my-colors",
      component: "myColorsComponent",
      access: true,
    })
    .state("report", {
      url: "/color-reports/:id",
      component: "reportComponent",
    })
    .state("blogPage", {
      url: "/blog/:id",
      component: "blogPageComponent",
    })
    .state("featureColor", {
      url: "/feature-colors",
      component: "featureColorComponent",
    })
    .state("purchases", {
      url: "/purchases",
      component: "purchasesComponent",
      access: true,
    })
    .state("about", {
      url: "/about",
      component: "aboutComponent",
    })
    .state("committeeMembers", {
      url: "/committee-members",
      component: "committeeMembersComponent",
    })
    .state("archiveArtifacts", {
      url: "/archive-artifacts",
      component: "archiveArtifactsComponent",
      access: true,
    })
    // .state('specialCollections', {
    // 	url: '/special-collections',
    // 	component: 'specialCollectionsComponent'
    // })
    .state("specialCollection", {
      url: "/special-collection/:id",
      component: "specialCollectionComponent",
    })
    .state("thankYou", {
      url: "/thank-you?id",
      component: "thankYouComponent",
    })
    .state("questions", {
      url: "/questions",
      component: "questionsComponent",
    })
    .state("questionPage", {
      url: "/questions/:id",
      component: "questionPageComponent",
    })
    .state("library", {
      url: "/library",
      component: "libraryComponent",
    })
    .state("siteFeatures", {
      url: "/site-features",
      component: "siteFeaturesComponent",
    })
    .state("resetPass", {
      url: "/reset-pass",
      component: "resetPass",
    })
    .state("membershipOption", {
      url: "/membership-option",
      component: "membershipOption",
    })
    .state("membershipBenefits", {
      url: "/membership-benefits",
      component: "membershipBenefits",
    })
    .state("membershipQuestion", {
      url: "/membership-question",
      component: "membershipQuestion",
    });
}
