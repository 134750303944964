angular
	.module('app')
	.component('cartComponent', {
		templateUrl: 'app/components/cart/cart.tmpl.html',
		controller: function ($state, $http, $window) {

			this.items = [
				{
					src: '../../../assets/images/cart-img.jpg',
					title: 'Report: Woman SS 2018',
					description: 'As China’s online video market evolves, brands are investing in livestreams and short video content on platforms like Tmall and Miaopai while de-prioritizing Youk...',
					quantity: 1,
					price: 200,
					totalPrice: function () {
						return this.price * this.quantity;
					}
				},
				{
					src: '../../../assets/images/related-reports-img.jpg',
					title: 'Report: Woman SS 2018',
					description: 'As China’s online video market evolves, brands are investing in livestreams and short video content on platforms like Tmall and Miaopai while de-prioritizing Youk...',
					quantity: 2,
					price: 220,
					totalPrice: function () {
						return this.price * this.quantity;
					}
				},
				{
					src: '../../../assets/images/cart-img.jpg',
					title: 'Report: Woman SS 2018',
					description: 'As China’s online video market evolves, brands are investing in livestreams and short video content on platforms like Tmall and Miaopai while de-prioritizing Youk...',
					quantity: 3,
					price: 260,
					totalPrice: function () {
						return this.price * this.quantity;
					}
				}
			];

			this.downItem = function (item) {
				item.quantity = --item.quantity;
				
				if (!item.quantity) {
					this.items.splice(this.items.indexOf(item), 1);
				}
			}

			this.upItem = function (item) {
				item.quantity = ++item.quantity;
			}

			this.goBack = function () {
				$window.history.back();
			};

			this.subTotal = function () {
				let sum = 0;

				this.items.forEach(function (item) {
					sum += item.totalPrice();
				});

				return sum;
			}
		}
	})