angular
	.module('app')
	.component('featureColorComponent', {
		templateUrl: 'app/components/feature-color/feature-color.tmpl.html',
		controller: function ($http, $scope, appConfig) {
			const vm = this;
			let perPage = 10,
				numPage = 1;

			$scope.resSearch = true;
			$scope.notification = {loading: 'Loading...', notFound: ''};
			$scope.family = '';
			vm.featureColors = [];

			$scope.init = () => {
				$http.get(`${appConfig.dashboardServiceUrl}api/home/featured_color_names.json?per_page=${perPage}&page=${numPage}`)
					.then((res) => {
						vm.featureColors = [...vm.featureColors, ...res.data.color_names];
						vm.total_pages = res.data.paginate.total_pages;
						vm.listArchiveLinks = res.data.families;
						$scope.notification.loading = false;
					})
					.catch((err) => {
						$scope.notification.loading = false;
						$scope.resSearch = false
					});
			};
			$scope.init();

			this.searchFeaturedColorsByFamily = (family) => {
				$scope.notification = {loading: 'Loading...', notFound: ' '};
				numPage = 1;
				vm.featureColors = [];

				if(family === '&family=All') {
					family = "";
				}

				$scope.family = family;

				$http.get(`${appConfig.dashboardServiceUrl}api/home/featured_color_names.json?per_page=${perPage}&page=${numPage}${family}`)
					.then((res) => {
						if(res.data.color_names.length) {
							$scope.notification = { loading: '', notFound: ''};
							vm.featureColors = [...vm.featureColors, ...res.data.color_names];
							vm.total_pages = res.data.paginate.total_pages;
						} else {
							$scope.notification = { loading: '', notFound: 'Not Found'};
						}
					})
					.catch((err) => {
						if (err.status === 404) {
							$scope.resSearch = false;
						}
					});
			};

			this.url = (post) => {
				if (post.archive_forecasts.length) {
					return '/archive-forecast/'+post.archive_forecasts[0].id
				} else if (post.artifacts.length) {
					return '/archive-artifact/'+post.artifacts[0].id
				} else {
					return '/archive-forecasts/'
				}
			};

			this.hasMoreReportsToShow = () => numPage < vm.total_pages;

			this.showMoreItems = () => {
				numPage += 1;

				if ($scope.family) {
					$http.get(`${appConfig.dashboardServiceUrl}api/home/featured_color_names.json?per_page=${perPage}&page=${numPage}${$scope.family}`)
						.then((res) => {
							if (res.data.color_names) {
								$scope.notification = {loading: '', notFound: ''};
								vm.featureColors = [...vm.featureColors, ...res.data.color_names];
								vm.total_pages = res.data.paginate.total_pages;
							} else {
								$scope.notification.notFound = 'Not Found';
								$scope.resSearch = false;
							}
						})
						.catch((err) => {
							if (err.status === 404) {
								$scope.resSearch = false;
							}
						});
				} else {
					$scope.init();
				}
			};
		}
	});
