angular.module('app').directive('toggleSwitch', function () {
  function link ($scope) {
    $scope.toggle = function () {
      $scope.activated = !$scope.activated;
      $scope.onToggle({ $activated: $scope.activated });
    };
  }

  return {
    restrict: 'E',
    templateUrl: 'app/directives/drToggleSwitch/drToggleSwitchView.html',
    link,
    scope: {
      activated: '=',
      onToggle: '&'
    }
  }
});
