angular
	.module('app')
	.component('standardComponent', {
		templateUrl: 'app/components/standard/standard.tmpl.html',
		controller: function ($scope, $state, $http, appConfig, $stateParams, ngNotify, modalService, $cookies, $window) {
			const vm = this;
			vm.perPage = 4;
			vm.relatedStandards = null;
			$scope.loading = true;
			var widthDocument = document.documentElement.clientWidth;
			vm.checkedCheckboxes = [];
			$scope.hiddenModal = true;

			$http.get(`${appConfig.dashboardServiceUrl}api/standards/${$stateParams.id}.json`)
				.then((res) => {
					if (res.data) {
						vm.standard = res.data;
						this.slides = res.data.sliders;
						$scope.loading = false;
					}
				});

			$http.get(`${appConfig.dashboardServiceUrl}api/standards.json?per_page=${vm.perPage}`)
				.then((res) => {
					vm.relatedStandards = res.data.standards;
					$scope.loading = false;
				});

			$scope.processingCheckbox = function (event, id) {
				vm.standard.color_names.map(el => {
					if (el.id !== id) {
						el.done = false;
					}
				});

				positionPopup(event);
			};

			$scope.visitStore = function () {
				$window.open(vm.standard.store_url, '_blank');
			};

			function positionPopup(event) {
				var side = widthDocument - event.clientX;

				if (side < 640) {
					$('.popup').css('right', '120px');
				} else {
					$('.popup').css('left', '120px');
				}
			};

			$scope.checkedArchiveCheckbox = function (color) {
				if (color.done) {
					$scope.hidden = false;
				}
			};

			this.saveColor = id => {
				var color_name_ids = {'color_name_ids': [id]};

				$http.post('https://colorassociationus.herokuapp.com/api/profile/color_names/add.json',
					color_name_ids,
					{
						headers: {
							'Authorization': 'Token token=' + $cookies.get('caus_session'),
							'Accept': 'application/json'
						}
					}
				).then(res => {
					if (res && res.data) {
						if (res.data.color_names && res.data.profile) {
							ngNotify.set('Your color have been successfully saved!', {
								type: 'success',
								duration: 3000
							});
							vm.standard.color_names.map(el => el.done = false);
						}
					}
				}).catch(err => {
					if (err.status === 401) {
						modalService.showModal(6);
					}
					vm.standard.color_names.map(el => el.done = false);
				});
			};
		}
	});
