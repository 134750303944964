angular.module('app').directive('drSlider', function () {
	function link(scope) {
		$(document).ready(function () {
			$('.slider-forecast').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				dots: true,
				infinite: false
			});
		});
	}

	return {
		restrict: 'E',
		templateUrl: 'app/directives/drSlider/drSliderView.html',
		link: link,
		scope: {
			images: '='
		}
	};
});
