angular
	.module('app')
	.component('specialCollectionsComponent', {
		templateUrl: 'app/components/special-collections/special-collections.tmpl.html',
		controller: function ($scope, appConfig, $http, headerConfig, $cookies) {
			const vm = this;

			vm.authorizedUser = false;

			if ($cookies.get('caus_session') !== undefined) {
				vm.authorizedUser = true;
			}

			let perPage = 8,
				numPage = 1;

			$scope.special_collections = [];
			$scope.filterList = [];
			$scope.showSelectors = false;
			$scope.notification = "";
			$scope.searchStr = '';

			const headersConfig = headerConfig.get();

			$scope.init = () => {
				vm.loading = true;
				$scope.notification = "";
				const currentUrl = `${appConfig.dashboardServiceUrl}/api/special_collections.json?page=${numPage}&per_page=${perPage}`;

				$http.get(`${currentUrl}${$scope.searchStr.length ? $scope.searchStr : ''}`, headersConfig)
					.then((res) => {
						const {special_collections} = res.data;
						vm.total_pages = res.data.paginate.total_pages;
						var collections = [];

						if (!$scope.filterList.length) {
							const {categories = [], seasons = [], years = [], popularity = []} = res.data;
							collections = categories;
							$scope.filterList = [[], [], years, popularity, collections];
							$scope.showSelectors = true;
						}

						if (!special_collections.length && !$scope.special_collections.length) {
							$scope.notification = "Not Found";
						} else {
							$scope.notification = "";
							vm.loading = false;
						}

						$scope.special_collections = [...$scope.special_collections, ...special_collections];
					});
			}

			$scope.init();

			$scope.filterBySearch = (multiSearch) => {
				$scope.searchStr = '';
				vm.total_pages = 0;

				for (item in multiSearch) {
					$scope.searchStr += `&${item}=${multiSearch[item]}`;
				}

				numPage = 1;
				$scope.special_collections = [];
				$scope.init();
			};

			this.showMoreItems = () => {
				numPage += 1;
				$scope.init();
			};

			this.hasMoreReportsToShow = () => numPage < vm.total_pages && vm.loading !== true;
		}
	});
