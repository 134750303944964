angular
	.module('app')
	.component('purchasesComponent', {
		templateUrl: 'app/components/purchases/purchases.tmpl.html',
		controller: function ($state, $http, appConfig, $stateParams) {
            var vm = this;
            
            vm.myPurchases = [
                {
                    category: 'Report',
                    img_url: "../../../assets/images/forecast-img.jpg",
                    title: 'Design with Data: Leveraging Color Analytics to inform, inspire and validate creative design decisions',
                    price: 100,
                    date: '01.04.2019'
                },
                {
                    category: 'Report',
                    img_url: "../../../assets/images/forecast-img.jpg",
                    title: 'Design with Data: Leveraging Color Analytics to inform, inspire and validate creative design decisions Design with Data: Leveraging Color Analytics to inform, inspire and validate creative design decision',
                    price: 100,
                    date: '01.04.2019'
                },
                {
                    category: 'Report',
                    img_url: "../../../assets/images/forecast-img.jpg",
                    title: 'Design with Data: Leveraging Color Analytics to inform, inspire and validate creative design decisions',
                    price: 100,
                    date: '01.04.2019'
                },
                {
                    category: 'Report',
                    img_url: "../../../assets/images/forecast-img.jpg",
                    title: 'Design with Data: Leveraging Color Analytics to inform, inspire and validate creative design decisions',
                    price: 100,
                    date: '01.04.2019'
                },
                {
                    category: 'Report',
                    img_url: "../../../assets/images/forecast-img.jpg",
                    title: 'Design with Data: Leveraging Color Analytics to inform, inspire and validate creative design decisions',
                    price: 100,
                    date: '01.04.2019'
                },
                {
                    category: 'Report',
                    img_url: "../../../assets/images/forecast-img.jpg",
                    title: 'Design with Data: Leveraging Color Analytics to inform, inspire and validate creative design decisions',
                    price: 100,
                    date: '01.04.2019'
                },
                {
                    category: 'Report',
                    img_url: "../../../assets/images/forecast-img.jpg",
                    title: 'Design with Data: Leveraging Color Analytics to inform, inspire and validate creative design decisions',
                    price: 100,
                    date: '01.04.2019'
                }
            ];
		}
	});