angular
	.module('app')
	.component('archiveArtifactsComponent', {
		templateUrl: 'app/components/archive-artifacts/archive-artifacts.tmpl.html',
		controller: function ($scope, appConfig, $http, headerConfig) {
			const vm = this;

			let perPage = 8,
				numPage = 1;

			$scope.artifacts = [];
			$scope.filterList = [];
			$scope.showSelectors = false;
			$scope.notification = "";
			$scope.searchStr = '';

			const headersConfig = headerConfig.get();

			$scope.init = () => {
				vm.loading = true;
				$scope.notification= "";
				const currentUrl = `${appConfig.dashboardServiceUrl}/api/artifacts.json?page=${numPage}&per_page=${perPage}`;

				$http.get(`${currentUrl}${$scope.searchStr.length ? $scope.searchStr : ''}`, headersConfig)
					.then((res) => {
						const { artifacts } = res.data;
						vm.total_pages = res.data.paginate.total_pages;

							const { categories = [], seasons = [], years = [], popularities = [], collections = []} = res.data;
							$scope.filterList = [categories, seasons, years, popularities, collections];
							$scope.showSelectors = true;

						if(!artifacts.length && !$scope.artifacts.length) {
							$scope.notification = "Not Found";
						} else {
							$scope.notification = "";
							vm.loading = ""
						}

						$scope.artifacts = [...$scope.artifacts, ...artifacts];
					});
			};

			$scope.init();

			$scope.filterBySearch = (multiSearch) => {
				$scope.searchStr = '';
				vm.total_pages = 0;

				for (item in multiSearch) {
					$scope.searchStr += `&${item}=${multiSearch[item]}`;
				}

				numPage = 1;
				$scope.artifacts = [];
				$scope.init();
			};

			this.showMoreItems = () => {
				numPage += 1;
				$scope.init();
			};

			this.hasMoreReportsToShow = () => numPage < vm.total_pages && vm.loading !== true;
		}
	});
