angular.module('app')
  .service('libraryService', function ($http, appConfig) {
    this.fetchAllBooks = function () {
      const endpoint = appConfig.dashboardServiceUrl + 'api/books.json';
      return $http.get(endpoint).then(res => res.data);
    };

    this.fetchBooksByCategory = function (selectedCategory) {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/books.json`,
        method: 'GET',
        params: { category: selectedCategory }
      })
        .then(res => res.data);
    };

    this.fetchBooksByQuery = function (selectedCategory, searchQuery) {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/books.json`,
        method: 'GET',
        params: { category: selectedCategory, query: searchQuery }
      })
        .then(res => res.data);
    }
});
