angular.module('app').directive('drCartOrderReview', function () {
	function process() {
    }
    return {
		restrict: 'E',
      templateUrl: 'app/directives/drCartOrderReview/drCartOrderReviewView.html',
			process: process,
			scope: false
	};
});
