angular
  .module('app')
  .component('libraryComponent', {
    templateUrl: 'app/components/library/library.tmpl.html',
    controller: function (modalService, libraryService, dynamicContent, $cookies) {
      this.searchQuery = '';
      this.dynamicContent = '';
      this.loading = true;
      this.categoriesOptions = [
        { id: -1, title: 'ALL' }
      ];
      this.selectedCategory = this.categoriesOptions[0];

      this.authorizedUser = $cookies.get('caus_session') !== undefined;

      this.fetchCategories = () => (
        libraryService
          .fetchBooksByCategory()
          .then((data) => {
            const categories = [];
            data.categories.forEach((item, index) => {
              categories.push({id: index, title: item});
            });
            this.categoriesOptions = [...this.categoriesOptions, ...categories];
          })
      );

      this.fetchCategories();

      this.fetchBooksAll = () => (
        libraryService
          .fetchAllBooks()
          .then((data) => {
            this.books = data.books;
            this.selectedBook = this.books[0];
            this.loading = false;
          })
      );

      this.fetchBooksByCategory = () => (
        libraryService
          .fetchBooksByCategory(this.selectedCategory.title)
          .then((data) => {
            this.books = data.books;
            this.selectedBook = this.books[0];
            this.loading = false;
          })
      );

      this.fetchBooksAll();

      dynamicContent
        .getDynamicContentByKey('library')
        .then((content) => this.dynamicContent = content);

      this.handleCategoryChange = () => {
        this.loading = true;
        this.selectedCategory.title === 'ALL' ?
          this.fetchBooksAll()
          :
          this.fetchBooksByCategory()
      };

      this.handleSearch = () => {
        this.loading = true;
        const title = this.selectedCategory.title === 'ALL' ? '' : this.selectedCategory.title;
          libraryService
          .fetchBooksByQuery(title, this.searchQuery)
          .then((data) => {
            this.books = data.books;
            this.selectedBook = this.books[0];
            this.loading = false;
          })
      };

      this.selectBook = (book) => {
        this.selectedBook = book;
      };

      this.showSelectedBookCover = () => {
        modalService.showModal(10, {
          image_url: this.selectedBook.image_url,
          isBook: true,
        });
      };

      this.formRange = (num) => num ? [].constructor(Math.round(num)) : [];

    }
  });
