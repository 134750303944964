angular
	.module('app')
	.component('aboutComponent', {
		templateUrl: 'app/components/about/about.tmpl.html',
		controller: function ($http, dataValidate, ngNotify, appConfig, $cookies, modalService, dynamicContent, $location, contactSubmit) {
			var vm = this;
			this.isFormSending = false;

			this.user = {
				first_name: {value: '', required: true, name: 'first name', type: 'provide'},
				last_name: {value: '', required: true, name: 'last name', type: 'provide'},
				email: {value: '', required: true, name: 'email', type: ''},
				company: {value: '', required: true, name: 'company name', type: 'provide'},
				phone: {value: '', name: 'phone number', type: 'enter'},
				title: {value: '', name: 'type', type: 'provide'}
			};

			dynamicContent.getDynamicContentByKey('about-inquire-about-membership').then(function (content) {
				vm.aboutInquireText = content;
			});

			this.contactUs = function () {
				if (dataValidate.validate(this.user)) {
					vm.isFormSending = true;
					contactSubmit.send(contactSubmit.membershipFormRawDataForApi(this.user))
						.then(function () {
							ngNotify.set('You have sent your registration request. Thank you!', {
								type: 'success',
								duration: 4000
							});
							$location.path('/thank-you');
						})
						.finally(() => vm.isFormSending = false);
				}
			};

			$http.get(`${appConfig.dashboardServiceUrl}api/home/resources.json`)
				.then((res) => {
					const { archive_forecast, standard } = res.data;
					if(archive_forecast || standard) {
						vm.archive_forecast = archive_forecast;
						vm.standard = standard;
					}
				});

			$http.get(`${appConfig.dashboardServiceUrl}api/reports.json?page=1&per_page=1`, {headers: {
				"Authorization": `Token token=${$cookies.get('caus_session')}`}
			})
				.then((res) => {
					vm.report = res.data.reports[0];
				})
				.catch((err) => {});
		}
	});
