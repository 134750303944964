angular
  .module('app')
  .component('landingComponent', {
    templateUrl: 'app/components/landing/landing.tmpl.html',
    controller: function ($scope, $http, appConfig, $location, questionsService) {
      let accessForRedirectHeaderLinks;
      const vm = this;
      $(document).ready(function () {
        setTimeout(function () {
          const link1 = new Typed('#link_1', {
            strings: ['Blog', 'Color'],
            typeSpeed: 50,
            backSpeed: 80,
            backDelay: 800,
            onComplete: (self) => {
              $(self.cursor).hide();
            },
            preStringTyped: (arrayPos, self) => {
              $(self.cursor).css('display', 'inline');
            }
          });

          const link2 = new Typed('#link_2', {
            strings: ['Artifacts', 'Trends'],
            typeSpeed: 50,
            backSpeed: 50,
            startDelay: 300,
            backDelay: 600,
            onComplete: (self) => {
              $(self.cursor).hide();
            },
            preStringTyped: (arrayPos, self) => {
              $(self.cursor).css('display', 'inline');
            }
          });

          const link3 = new Typed('#link_3', {
            strings: ['Questions', 'Standards'],
            typeSpeed: 50,
            backSpeed: 50,
            startDelay: 800,
            backDelay: 1000,
            onComplete: (self) => {
              $(self.cursor).hide();
            },
            preStringTyped: (arrayPos, self) => {
              $(self.cursor).css('display', 'inline');
            }
          });
        }, 400);

        setTimeout(function () {
          accessForRedirectHeaderLinks = true;
        }, 4000);
      });

      accessForRedirectHeaderLinks = false;

      vm.headerLinks = (link, type) => {
        if (accessForRedirectHeaderLinks) {
          switch (type) {
            case 'forecast':
              $location.path(link);
              break;
            case 'standard':
              $location.path(link);
              break;
            case 'color-names':
              $location.path(link);
              break;
          }
        }
      };

      $http.get(`${appConfig.dashboardServiceUrl}api/home/resources.json`)
        .then((res) => {
          const {archive_forecast, standard, forecast} = res.data;
          if (archive_forecast || standard || forecast) {
            vm.archive_forecast = archive_forecast;
            vm.archive_forecast.description = $(archive_forecast.description.replace(/<style([^<]+?\s?)+<\/style>/g,'')).text()
            vm.standard = standard;
            vm.forecast = forecast;
          }
        });

    $http.get(`${appConfig.dashboardServiceUrl}api/home/featured_color_names.json`)
        .then((res) => {
          vm.featureColor = res.data.color_names[0];
        })
        .catch((err) => {
        });

      $http.get(`${appConfig.dashboardServiceUrl}api/home/archive_forecasts.json`)
        .then((res) => {
          vm.archive_forecasts = res.data;
        })
        .catch((err) => {
        });

      vm.goToNews = function () {
        $location.path('/press');
      };

      questionsService.getLastQuestion().then(lastQuestion => {
        $scope.lastQuestion = lastQuestion;
      });
    }
  });
