angular
	.module('app')
	.component('archiveForecastsComponent', {
		templateUrl: 'app/components/archive-forecasts/archive-forecasts.tmpl.html',
		controller: function ($scope, appConfig, $http, headerConfig, $cookies, $location) {
			const vm = this;
			const headersConfig = headerConfig.get();

			vm.authorizedUser = $cookies.get('caus_session') !== undefined;

			let perPage = 8,
				numPage = 1;

			$scope.archive_forecasts = [];
			$scope.filterList = [];
			$scope.showSelectors = false;
			$scope.notification = "";
			// $scope.searchStr = '';

			$scope.init = () => {
				vm.loading = true;
				$scope.notification= "";
				const currentUrl = `${appConfig.dashboardServiceUrl}api/archive_forecasts.json?page=${numPage}&per_page=${perPage}`;
				$scope.searchStr = $location.url().replace('/archive-forecasts?', '&')
				if (headersConfig !== undefined) {
					$http.get(`${currentUrl}${$scope.searchStr.length ? $scope.searchStr : ''}`, headersConfig)
						.then((res) => {
							const { archive_forecasts } = res.data;
							vm.total_pages = res.data.paginate.total_pages;

								const { categories, seasons, years, popularity = [], collection = [] } = res.data;
								$scope.filterList = [categories, seasons, years, popularity, collection];
								$scope.showSelectors = true;

							if (!archive_forecasts.length && !$scope.archive_forecasts.length) {
								$scope.notification = "Not Found";
							} else {
								$scope.notification = "";
								vm.loading = ""
							}

							$scope.archive_forecasts = [...$scope.archive_forecasts, ...archive_forecasts];
						});
				} else {
					vm.loading = false;
					$scope.notification = "Not Found";
				}
			};

			$scope.init();

			$scope.filterBySearch = (multiSearch) => {
				$scope.searchStr = '';
				vm.total_pages = 0;

				for (item in multiSearch) {
					$scope.searchStr += `&${item}=${multiSearch[item]}`;
				}

				numPage = 1;
				$scope.archive_forecasts = [];
				$location.search($scope.searchStr);
				$scope.init();
			};

			this.showMoreItems = () => {
				numPage += 1;
				$scope.init();
			};

			this.hasMoreReportsToShow = () => numPage < vm.total_pages && vm.loading !== true;
		}
	});
