angular.module('app').service('modalService', function ($rootScope, $uibModal) {
	 this.showModal = function (number, item, text, img, flag) {
		var arr = [
			{
				tmpl: 'app/components/modal/modal.tmpl.html',
				class: 'modal-err'
			},
			{
				tmpl: 'app/components/modal/modalInfographic.tmpl.html',
				class: 'modal-infographic',
				windowClass: 'modal-big-size'
			},
			{
				tmpl: 'app/components/modal/graphic-modal.tmpl.html',
				class: 'graphic-modal'
			},
			{
				tmpl: 'app/components/modal/dailyModal.tmpl.html',
				class: 'graphic-modal'
			},
			{
				tmpl: 'app/components/modal/cat-survey-modal.tmpl.html',
				class: 'cart-modal'
			},
			{
				tmpl: 'app/components/modal/editOnlyAdmin.tmpl.html',
				class: 'modal-only-admin modal-err'
			},
			{
				tmpl: 'app/components/modal/membersOnlyModal.tmpl.html',
				class: 'adv-modal'
			  },
			{
				tmpl: 'app/components/modal/modalEditProfile.tmpl.html',
				class: 'modal-err'
			  },
			{
				tmpl: 'app/components/modal/pressSubmittedModal.tmpl.html',
				class: 'modal-err'
			 },
			{
				tmpl: 'app/components/modal/inquireAboutMembershipSubmittedModal.tmpl.html',
				class: 'modal-err'
			},
			{   // 10
				tmpl: 'app/components/modal/fullImage.tmpl.html',
				class: 'modal-fullimage',
				windowClass: 'modal-big-size'
			}
			];

		$uibModal.open({
			templateUrl: arr[number].tmpl,
			size: arr[number].size,
			windowClass: arr[number].windowClass,
			controller: function ($scope, $uibModalInstance) {
				$scope.ok = function () {
					$uibModal.close();
				};
				$scope.cancel = function () {
					$uibModalInstance.dismiss('cancel');
				};
				$scope.img = img;
				if (item) {
					$scope.item = item;
					if (item.profileInfo) {
						var itemArr = [];
						for (obj in item.profileInfo) {
							itemArr.push(`${obj} ${item.profileInfo[obj][0]}`);
						}
						$scope.item = itemArr;
					}
				}
				if (flag !== undefined) {
					$scope.flag = flag;
				}
				if (text) {
					$scope.text = text;
				}
			},
			windowTopClass: arr[number].class,
			resolve: {}
		}).result.then(function(){}, function(res){});
	}
});
