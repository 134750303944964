angular
	.module('app')
	.component('forecastComponent', {
		templateUrl: 'app/components/forecast/forecast.tmpl.html',
		controller: function ($scope, $state, appConfig, $http, headerConfig, $location, $stateParams, $cookies) {
			let vm = this;

			vm.authorizedUser = false;

			if($cookies.get('caus_session') !== undefined) {
				vm.authorizedUser = true;
			}

			$scope.redirectToTrends = () => {
				$location.path(`/forecast-trend-stories/${$scope.forecast.id}`);
			};

			const headersConfig = headerConfig.get();

			$scope.init = () => {
				$http.get(`${appConfig.dashboardServiceUrl}api/forecasts/${$stateParams.id}.json?`, headersConfig)
					.then((res) => {
						if (res && res.data) {
							$scope.forecast = res.data;

							$http.get(`${appConfig.dashboardServiceUrl}api/forecasts.json?&category=${$scope.forecast.category}&page=1&per_page=5`, headersConfig)
								.then((res) => {
									vm.forecasts = res.data.forecasts.filter((forecast) => forecast.id !== $scope.forecast.id);

									if (vm.forecasts.length >= 5) {
										vm.forecasts.length = 4;
									}

									if (!vm.forecasts.length) {
										$scope.notification = 'Not Found';
									} else {
										$scope.notification = '';
									}
								});

							if ($scope.forecast.sliders.length) {
								vm.slides = [...$scope.forecast.sliders];
							} else {
								vm.slides = [];
							}
						}
					});
			};

			$scope.init();
		}
	});
