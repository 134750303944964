angular.module('app').service('dynamicContent', function ($http, appConfig) {
  const url = `${appConfig.dashboardServiceUrl}api/settings.json`;

  function getAll () {
    return $http.get(url).then(function (res) {
      return res.data;
    });
  }

  function getProfileCategories () {
    return $http.get(url).then(function (res) {
      return res.data.profile_categories;
    });
  }

  function getDynamicContentByKey (key) {
    return $http.get(url).then(function (res) {
      var content =  res.data.dynamic_contents.find(function (item) {
        return item.key === key;
      });

      if (content) {
        return content.body;
      } else {
        return null;
      }
    });
  }

  function getDynamicContentsByKeys (keys) {
    return $http.get(url).then(function (res) {
      var results = [];
      res.data.dynamic_contents.forEach(function (item) {
        if (keys.includes(item.key)) {
          results[item.key] = item.body;
        }
      });
      return results;
    });
  }

  return {
    getAll: getAll,
    getProfileCategories: getProfileCategories,
    getDynamicContentByKey: getDynamicContentByKey,
    getDynamicContentsByKeys: getDynamicContentsByKeys
  }

});
