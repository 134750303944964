angular
  .module('app')
  .component('thankYouComponent',  {
    templateUrl: 'app/components/thank-you/thank-you.tmpl.html',
    controller: function ($scope, $location) {
      $scope.message = $location.search().state && $location.search().state === 'true' ?
        'Look for our next post with new and interesting color stories.'
        : 'Thank you for contacting CAUS. We\'ll get back to you shortly.'
    }
  });
