angular.module('app').directive('drSelectorCategory', ['categoryValues', 'modalService', function (categoryValues, modalService) {
	function link(scope) {
        scope.categoryList = categoryValues(scope.categoryValue);
        
        if(angular.isArray(scope.modelScope) && !scope.modelScope.length) {
            scope.modelScope = scope.categoryList[0].title;
        }

        $(document).ready(function(){
            var selectBtn = $('.select-profile__btn'),
                selectList = $('.select-profile__list');

            if (scope.modelScope[scope.modelName] === scope.categoryList[0].title) {
                    selectBtn.css({'font-weight' : '700'});
                    selectBtn.children().show();
            }
            scope.showList = function (e) {
                var target = e.target;

                if(target.classList.contains('select-profile__btn_active')) {
                    target.classList.remove('select-profile__btn_active');
                    $(target).next('.select-profile__list').removeClass('select-profile__list_active');
                } else {
                    selectBtn.removeClass('select-profile__btn_active');
                    selectList.removeClass('select-profile__list_active');
                    target.classList.add('select-profile__btn_active');
                    $(target).next('.select-profile__list').addClass('select-profile__list_active');
                }
            }

            scope.hideList = function (e) {
                var target = e.target;
                var curentLabel =  $(target).parents('.select-profile__list');

                if(curentLabel.closest('.profile__edit-item_disabled').length) {
                    return modalService.showModal(5);
                }

                if ($(target).next('input').val() === scope.categoryList[0].title) {
                    curentLabel.prev().css({'font-weight' : '700'});
                    curentLabel.prev().children().show();
                } else {
                    curentLabel.prev().css({'font-weight' : 'normal'});
                    curentLabel.prev().children().hide();
                }

                curentLabel.toggleClass('select-profile__list_active');
                curentLabel.prev().toggleClass('select-profile__btn_active');
            }

            $(document).click(function (event) {
                if ($(event.target).closest('.select-profile').length === 0) {
                    selectBtn.removeClass('select-profile__btn_active');
                    selectList.removeClass('select-profile__list_active');
                }

                event.stopPropagation();
            });
        });
    }

	return {
		restrict: 'E',
		templateUrl: 'app/directives/drSelectorCategory/drSelectorCategoryView.html',
		link: link,
		replace: true,
		scope: {
			categoryValue: '@',
			modelScope: '=',
			modelName: '@'
		}
	};
}]);
