angular
	.module('app')
	.component('inquireAboutMembershipComponent', {
		templateUrl: 'app/components/inquire-about-membership/inquire-about-membership.tmpl.html',
		controller: function ($state, $http, dataValidate, ngNotify, appConfig, $location, dynamicContent, contactSubmit) {
			var self = this;
			self.isFormSending = false;

			dynamicContent.getDynamicContentsByKeys(['full-membership', 'online-membership']).then(function (content) {
				self.fullMembershipText = content['full-membership'];
				self.onlineMembershipText = content['online-membership'];
			});

			this.user = {
				first_name: {value: '', required: true, name: 'first name', type: 'provide'},
				last_name: {value: '', required: true, name: 'last name', type: 'provide'},
				email: {value: '', required: true, name: 'email', type: ''},
				company: {value: '', required: true, name: 'company name', type: 'provide'},
				phone: {value: '', name: 'phone number', type: 'enter'},
				title: {value: '', name: 'type', type: 'provide'}
			};

			this.contactUs = function () {
				const isDataValid = dataValidate.validate(this.user);

				if (isDataValid) {
					self.isFormSending = true;
					contactSubmit.send(contactSubmit.membershipFormRawDataForApi(this.user))
						.then(function () {
							ngNotify.set('You have sent your registration request. Thank you!', {
								type: 'success',
								duration: 4000
							});
							$location.path('/thank-you');
						})
						.finally(() => self.isFormSending = false)
				}
			};
		}
	});
