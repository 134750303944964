angular.module("app").component("contactComponent", {
	templateUrl: "app/components/contact/contact.tmpl.html",
	controller: function (
		$http,
		appConfig,
		modalService,
		dataValidate,
		ngNotify,
		$location,
		contactSubmit
	) {
		var vm = this
		this.isFormSending = false

		vm.data = {
			first_name: {
				value: "",
				required: true,
				name: "first name",
				type: "provide",
			},
			last_name: {
				value: "",
				required: true,
				name: "last name",
				type: "provide",
			},
			title: { value: "", name: "title", type: "" },
			company: {
				value: "",
				required: true,
				name: "company name",
				type: "provide",
			},
			phone: { value: "", name: "phone number", type: "enter" },
			email: { value: "", required: true, name: "email", type: "provide" },
			comments: { value: "", name: "comments", type: "enter" },
		}

		this.contactUs = function () {
			if (dataValidate.validate(this.data)) {
				vm.isFormSending = true
				contactSubmit
					.send(contactSubmit.contactFormRawDataForApi(vm.data))
					.then((res) => {
						ngNotify.set("We will contact you soon. Thank you!", {
							type: "success",
							duration: 4000,
						})
						$location.path("thank-you")
					})
					.catch((err) => modalService.showModal(0, { profileInfo: err.data }))
					.finally(() => (vm.isFormSending = false))
			}
		}
	},
})
