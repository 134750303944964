angular.module('app')
	.factory('serviceItem', function () {
		var savedData = [];

		function set(array) {
			savedData = array;
		}

		function get() {
			return savedData;
		}

		function getAll() {
			var reports = [];
			for (var i = 1; i < 25; i++) {
				reports.push('report ' + i);
			}
			return reports;
		}

		return {
			set: set,
			get: get,
			getAll: getAll
		};
	});
