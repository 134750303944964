angular
  .module('app')
  .service('questionsService', function (appConfig, $http) {
    const perPage = 10;

    this.getPage = function (pageNumber) {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/questions.json`,
        method: 'GET',
        params: { page: pageNumber, per_page: perPage }
      }).then(data => data.data)
    };

    this.getQuestion = function (id) {
      return $http.get(`${appConfig.dashboardServiceUrl}api/questions/${id}.json`);
    };

    this.getLastQuestion = function () {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/questions.json`,
        method: 'GET',
        params: { per_page: 1, page: 1 }
      })
        .then(data => data.data.paginate.total_entries)
        .then(totalQuestions => {
          return $http({
            url: `${appConfig.dashboardServiceUrl}api/questions.json`,
            method: 'GET',
            params: { per_page: 1, page: totalQuestions }
          }).then(data => data.data.questions[0]);
        });
    }

  });
