angular
  .module('app')
  .component('membershipQuestion', {
    templateUrl: 'app/components/membership-question/membership-question.tmpl.html',
    controller: function ($scope) {

      this.pricingQuestions = [
        {
          id: 0,
          question: 'What is CAUS 7-day satisfaction guarantee?',
          answer: 'All CAUS subscriptions come with a 7-day. 100% money-back guarantee. If you are not finding the benefits of being a member of CAUS, we will issue a refund. Please email us to start the refund process.'
        },
        {
          id: 1,
          question: 'How often does CAUS update their forecast reports?',
          answer: 'All CAUS subscriptions come with a 7-day. 100% money-back guarantee. If you are not finding the benefits of being a member of CAUS, we will issue a refund. Please email us to start the refund process.'
        },
        {
          id: 2,
          question: 'I forgot my login information?',
          answer: 'All CAUS subscriptions come with a 7-day. 100% money-back guarantee. If you are not finding the benefits of being a member of CAUS, we will issue a refund. Please email us to start the refund process.'
        },
        {
          id: 3,
          question: 'How do I upgrade my subscription?',
          answer: 'All CAUS subscriptions come with a 7-day. 100% money-back guarantee. If you are not finding the benefits of being a member of CAUS, we will issue a refund. Please email us to start the refund process.'
        },
        {
          id: 4,
          question: 'How do I cancel my CAUS subscription?',
          answer: 'All CAUS subscriptions come with a 7-day. 100% money-back guarantee. If you are not finding the benefits of being a member of CAUS, we will issue a refund. Please email us to start the refund process.'
        }
      ];
    }
  });
