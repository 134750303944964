angular.module('app')
	.service('userParams', function () {
		var savedData;

		function set(obj) {
			savedData = obj;
		}

		function get() {
			return savedData;
		}

		return {
			set: set,
			get: get
		};
	});
