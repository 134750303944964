angular
  .module('app')
  .component('membershipOption', {
    templateUrl: 'app/components/membership-option/membership-option.tmpl.html',
    controller: function (localStorageService, $scope, $http, appConfig) {
      this.annuallyMode = false;

      this.pricings = [
        {
          type: 'insights',
          description: 'who require digital forecast reports 12 month in advance',
          monthlyPrice: 12,
          totalPrice: 120,
          annuallyPrice: 10,
          billedPrice: 100,
          features: [
            {
              name: 'Trend Seminars',
              img_url: '/assets/images/icons/trend_seminars.png'
            },
            {
              name: 'Color Naming Index',
              img_url: '/assets/images/icons/color_naming_index.png'
            },
            {
              name: 'Forecast Archive',
              img_url: '/assets/images/icons/digital_forecasts.png'
            },
            {
              name: 'Monthly Color Recap',
              img_url: '/assets/images/icons/monthly_color_recap.png'
            }
          ]
        },
        {
          type: 'digital',
          description: 'who require digital forecast reports 24 month in advance',
          monthlyPrice: 30,
          totalPrice: 300,
          annuallyPrice: 20,
          billedPrice: 200,
          features: [
            {
              name: '9 Digital Forecasts',
              img_url: '/assets/images/icons/digital_forecasts.png'
            },
            {
              name: 'Trend Seminars',
              img_url: '/assets/images/icons/trend_seminars.png'
            },
            {
              name: 'Color Naming Index',
              img_url: '/assets/images/icons/color_naming_index.png'
            },
            {
              name: 'Forecast Archive',
              img_url: '/assets/images/icons/digital_forecasts.png'
            },
            {
              name: 'Monthly Color Recap',
              img_url: '/assets/images/icons/monthly_color_recap.png'
            }
          ]
        },
        {
          type: 'small business',
          description: 'who require digital & physical colors 24 month in advance',
          monthlyPrice: 75,
          totalPrice: 756,
          annuallyPrice: 50,
          billedPrice: 500,
          features: [
            {
              name: 'Forecast Binder',
              img_url: '/assets/images/icons/forecast_binder.png'
            },
            {
              name: 'All Digital Forecasts',
              img_url: '/assets/images/icons/digital_forecasts.png'
            },
            {
              name: 'Extra Swatch Set',
              img_url: '/assets/images/icons/extra_swatch_set.png'
            },
            {
              name: 'Online Access',
              img_url: '/assets/images/icons/online_access.png'
            },
            {
              name: 'All Benefits of Digital',
              img_url: '/assets/images/icons/all_benefits_of_digital.png'
            }
          ]
        }
      ];

      this.pricingQuestions = [
        {
          id: 0,
          question: 'What is CAUS 7-day satisfaction guarantee?',
          answer: 'All CAUS subscriptions come with a 7-day. 100% money-back guarantee. If you are not finding the benefits of being a member of CAUS, we will issue a refund. Please email us to start the refund process.'
        },
        {
          id: 1,
          question: 'How often does CAUS update their forecast reports?',
          answer: 'All CAUS subscriptions come with a 7-day. 100% money-back guarantee. If you are not finding the benefits of being a member of CAUS, we will issue a refund. Please email us to start the refund process.'
        },
        {
          id: 2,
          question: 'I forgot my login information?',
          answer: 'All CAUS subscriptions come with a 7-day. 100% money-back guarantee. If you are not finding the benefits of being a member of CAUS, we will issue a refund. Please email us to start the refund process.'
        },
        {
          id: 3,
          question: 'How do I upgrade my subscription?',
          answer: 'All CAUS subscriptions come with a 7-day. 100% money-back guarantee. If you are not finding the benefits of being a member of CAUS, we will issue a refund. Please email us to start the refund process.'
        },
        {
          id: 4,
          question: 'How do I cancel my CAUS subscription?',
          answer: 'All CAUS subscriptions come with a 7-day. 100% money-back guarantee. If you are not finding the benefits of being a member of CAUS, we will issue a refund. Please email us to start the refund process.'
        }
      ];
    }
  });
