angular.module("app").component("blogPageComponent", {
  templateUrl: "app/components/blog-page/blog-page.tmpl.html",
  controller: function ($sce, appConfig, $http, $stateParams) {
    const vm = this
    vm.latestPost = []
    vm.categoryList = []
    vm.archiveList = []

    vm.init = () => {
      $http
        .get(
          `${appConfig.dashboardServiceUrl}api/posts/${$stateParams.id}.json`
        )
        .then((res) => {
          vm.blog = res.data
          vm.blog.body = $sce.trustAsHtml(vm.blog.body.replace(/&nbsp;/g, " "))
        })
    }

    $http
      .get(`${appConfig.dashboardServiceUrl}api/posts.json?per_page=3`)
      .then((res) => {
        if (res && res.data) {
          vm.categoryList = [...res.data.categories]
          vm.archiveList = [...res.data.dates]
          vm.latestPost = [...res.data.posts]
        }
      })
  },
})
