angular
	.module('app')
	.component('specialCollectionComponent', {
		templateUrl: 'app/components/special-collection/special-collection.tmpl.html',
		controller: function ($scope, $state, $http, serviceItem, ngNotify, appConfig, $stateParams, modalService, $cookies, headerConfig) {
			var vm = this;
			vm.authorizedUser = false;

			if($cookies.get('caus_session') !== undefined) {
				vm.authorizedUser = true;
			}
			vm.checkedCheckboxes = [];
			vm.specialCollection;
			$http.get(appConfig.dashboardServiceUrl + `api/special_collections/${$stateParams.id}.json`)
				.then((res) => {
					if (res.data) {

						vm.specialCollection = res.data;

						vm.specialCollection.sliders = [{'image_url': vm.specialCollection.image_url}, {'image_url': vm.specialCollection.image_url}]

						if (vm.specialCollection.sliders.length) {
							vm.slides = [...vm.specialCollection.sliders];
						} else {
							vm.slides = [];
						}
					}
				})
				.catch((err) => {});
		}
	});
