angular
	.module('app')
	.component('privacyComponent', {
		templateUrl: 'app/components/privacy/privacy.tmpl.html',
		controller: function ($state, dynamicContent) {
			var self = this;

			dynamicContent.getDynamicContentByKey('privacy').then( function (content) {
				self.privacyText = content;
			});

		}
	});
