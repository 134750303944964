var app = angular.module('app', [
	'ngResource',
	'ngCookies',
	'ngSanitize',
	'countTo',
	'selector',
	'angular-extend-promises',
	'ui.router',
	'ui.select',
	'ui.bootstrap',
	'ngNotify',
	'LocalStorageModule',
	'ngFileUpload',
	'updateMeta',
	'angular-loading-bar',
	'selector'
]);

var config = {
	routeUrl: ''
};

app.value('config', config);
var run = ['$anchorScroll', '$rootScope', '$location',
	function ($anchorScroll, $rootScope) {
		$rootScope.$on('$viewContentLoaded', function () {
			angular.element('html, body').animate({scrollTop: 0}, 200);
		});
	}];

app.run(run);

app.run(function ($transitions, $cookies, modalService, localStorageService) {
	$transitions.onBefore({},
		function(transition) {
			var user = $cookies.get('caus_session');
			var access = transition.to().access;
			var from = transition.from().url !== '^' ? transition.from().url : '/';
			var admin = localStorageService.get('isAdmin') === true

			if (!user && access === true) {
				modalService.showModal(6);
				return transition.router.stateService.target(from);
			} else if(user && transition.to().component === 'loginComponent') {
				return transition.router.stateService.target('profile');
			}
			 else if (!admin && transition.to().component === 'committeeMembersComponent') {
			 	return transition.router.stateService.target(from)
			 }
		});
});

angular.module('app').constant('appConfig', {
	appName: 'colorassociation',
	authServiceUrl: '',
	// dashboardServiceUrl: '//www.colorassociation.com/'
	// dashboardServiceUrl: 'http://localhost:3000/'
	dashboardServiceUrl: 'https://colorassociationus.herokuapp.com/'
});

