angular.module("app").component("colorNamesComponent", {
	templateUrl: "app/components/color-names/color-names.tmpl.html",
	controller: function (
		$scope,
		$http,
		appConfig,
		ngNotify,
		$cookies,
		modalService,
		dynamicContent,
		colorNamesService,
		headerConfig
	) {
		var vm = this
		var widthDocument = document.documentElement.clientWidth
		var numPage = 1
		vm.searchColorName = ""
		vm.foundColorName = ""
		vm.checkedCheckboxes = []
		$scope.resSearch = false
		$scope.loading = true
		$scope.colorNames
		$scope.colorNamesShare
		$scope.loadingPagination
		$scope.paginationEnd = false
		$scope.hiddenModal = true

		$scope.exactMatchesCount = 0
		$scope.partialMatchesCount = 0

		$scope.shouldSortByAlph = false
		$scope.loadingPartials = false

		$scope.isSearchMode = false

		dynamicContent
			.getDynamicContentByKey("color-name")
			.then(function (content) {
				vm.colorNamesText = content
			})

		$http({
			method: "GET",
			url: "https://colorassociationus.herokuapp.com/api/profile.json",
			headers: {
				Authorization: "Token token=" + $cookies.get("caus_session"),
			},
		})
			.then(function (res) {
				if (res && res.data.color_names) {
					if (res.data.color_names.length === 0) {
						$scope.notification = {
							warning: "You don't have colors",
							error: true,
						}
					} else {
						vm.myColors = res.data.color_names
					}
				}
			})
			.catch(function (err) {
				$scope.notification = { warning: "You don't have colors", error: true }
				if (err.status === 401) {
					modalService.showModal(6)
				}
			})

		colorNamesService.getAll().then(function (data) {
			$scope.loading = false
			$scope.colorNames = data
		})

		// Added id, no data from backend yet
		if ($scope.colorNamesShare) {
			$scope.colorNamesShare = $scope.colorNamesShare.map((el, i) => {
				const copyObj = Object.assign({}, el)
				copyObj.id = i
				return copyObj
			})
		}

		$scope.processingCheckbox = function (event, id) {
			$scope.colorNames.map((el) => {
				if (el.id !== id) {
					el.done = false
				}
			})

			if ($scope.colorNamesShare) {
				$scope.colorNamesShare.map((el) => (el.done = false))
			}

			positionPopup(event)
		}

		$scope.processingCheckboxShare = function (event, id) {
			$scope.colorNamesShare.map((el) => {
				if (el.id !== id) {
					el.done = false
				}
			})
			$scope.colorNames.map((el) => (el.done = false))

			positionPopup(event)
		}

		function positionPopup(event) {
			var side = widthDocument - event.clientX
			if (side < 640) {
				$(".popup").css("right", "120px")
			} else {
				$(".popup").css("left", "120px")
			}
		}

		$scope.checkedArchiveCheckbox = function (color) {
			if (color.done) {
				vm.checkedCheckboxes.push(color)
			} else {
				vm.checkedCheckboxes.splice(vm.checkedCheckboxes.indexOf(color), 1)
			}
		}

		vm.pagination = () => {
			numPage += 1
			$scope.loadingPagination = true
			searchColor(vm.foundColorName, {
				changeOnlyPartial: false,
				usePrevQuery: false,
			})
		}

		this.searchColorHandle = function (
			options = { changeOnlyPartial: false, usePrevQuery: false }
		) {
			numPage = 1
			if (
				vm.searchColorName.length === 0 ||
				typeof vm.searchColorName === "undefined"
			) {
				return ($scope.msg = "please enter color name")
			}

			$scope.msg = ""

			if (options.changeOnlyPartial) {
				$scope.loadingPartials = true
			} else {
				$scope.loading = true
			}

			if (!options.usePrevQuery) {
				vm.foundColorName = vm.searchColorName
			}

			searchColor(vm.foundColorName, options)
		}

		function searchColor(colorName, options) {
			$scope.isSearchMode = true
			$scope.paginationEnd = false

			colorNamesService
				.getByQuery(colorName, numPage * 30, $scope.shouldSortByAlph)
				.then(function (res) {
					$scope.colorNamesShare = res.data.partial_matches
					$scope.partialMatchesCount = res.data.partial_matches_count

					$scope.exactMatchCategories = res.data.exact_match_categories
					$scope.partialMatchCategories = res.data.partial_match_categories

					if (!options.changeOnlyPartial) {
						$scope.colorNames = res.data.exact_matches
						$scope.exactMatchesCount = res.data.exact_matches_count

						if (res.data.exact_matches.length === 0) {
							$scope.notFound = true
						} else {
							$scope.loadingPagination = false
						}
					}

					$scope.loadingPartials = false
					$scope.loading = false

					if (numPage >= 5 || $scope.colorNamesShare.length < 30) {
						$scope.paginationEnd = true
					}

					$scope.resSearch = true
				})
		}

		this.saveColor = (id) => {
			var color_name_ids = { color_name_ids: [id] }

			$http
				.post(
					"https://colorassociationus.herokuapp.com/api/profile/color_names/add.json",
					color_name_ids,
					{
						headers: {
							Authorization: "Token token=" + $cookies.get("caus_session"),
							Accept: "application/json",
						},
					}
				)
				.then((res) => {
					if (res && res.data) {
						if (res.data.color_names && res.data.profile) {
							ngNotify.set("Your color have been successfully saved!", {
								type: "success",
								duration: 3000,
							})

							vm.myColors = res.data.color_names
							$scope.colorNames.map((el) => (el.done = false))

							if ($scope.colorNamesShare) {
								$scope.colorNamesShare.map((el) => (el.done = false))
							}
						}
					}
				})
				.catch((err) => {
					if (err.status === 401) {
						modalService.showModal(6)
					}
					$scope.colorNames.map((el) => (el.done = false))
					if ($scope.colorNamesShare) {
						$scope.colorNamesShare.map((el) => (el.done = false))
					}
				})
		}

		$scope.onToggleSorting = function (activated) {
			$scope.shouldSortByAlph = activated

			vm.searchColorHandle({
				changeOnlyPartial: true,
				usePrevQuery: true,
			})
		}
	},
})
