angular.module('app').directive( "modalColor", ['colorNamesService', function(colorNamesService){
	return {
		restrict: "E",
		templateUrl: "app/directives/drModalColor/drModalColorView.html",
		scope: true,
		transclude: true,
		link: function(scope, el) {
			$(el).find('.trans-layer').on('click', function() {
				scope.hidden = true;
				scope.$apply();
			});

			scope.relatedForecasts = [];
			scope.titleForecastId = null;

			try {
				scope.titleForecastId = scope.color.archive_forecasts[0].id;
			} catch (err) {}

			colorNamesService.getColorForecastsNames(scope.color.title).then(function (forecasts) {
				console.log(scope.color);
				const sortOrder = {
					season: {
						'fw': 1,
						'ss': 2,
						'': 3
					},
					category: {
						'men': 1,
						'women': 2,
						'youth': 3,
						'interion': 4
					}
				};

				forecasts.forEach(function (item) {
					if (
						!(`${item.category} ${item.season} ${item.year}`.toLocaleLowerCase() === `${scope.color.category} ${scope.color.season} ${scope.color.year}`.toLocaleLowerCase())
					) scope.relatedForecasts.push(item);
				});

				scope.relatedForecasts.sort(function (a, b) {
					if (b.year !== a.year) {
						return b.year - a.year;
					} else if (b.category.toLocaleLowerCase() !== a.category.toLocaleLowerCase()) {
						return sortOrder[b.category.toLocaleLowerCase()] - sortOrder[a.category.toLocaleLowerCase()];
					} else {
						return sortOrder[b.season.toLocaleLowerCase()] - sortOrder[a.season.toLocaleLowerCase()];
					}
				});
			});
		}
	}
}]);
