angular.module('app').directive('cutText', function ($timeout) {
  const DEFAULT_MAX_LENGTH = 180;

  function link(scope, elem) {
    $timeout(function() {
      const lengthToCut = scope.maxLength || DEFAULT_MAX_LENGTH;
      const inputText = elem[0].textContent;
      const outputText = inputText.length >= lengthToCut ? inputText.substring(0, lengthToCut-3) + '...': inputText;
      elem.text(outputText);
    });
  }

  return {
    link,
    restrict: 'A',
    scope: {
      maxLength: '@'
    }
  }
});
