angular
  .module('app')
  .component('questionsComponent', {
    templateUrl: 'app/components/questions/questions.tmpl.html',
    controller: function ($scope, questionsService) {
      $scope.totalPages = 1;
      $scope.curPage = 1;

      const sortArchiveDates = function (unsortedArchive) {
        const monthsOfYear = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september',
          'october', 'november', 'december'];
        const archive = [...unsortedArchive];

        archive.sort(function (a, b) {
          const [monthA, yearA] = a.split(' ');
          const [monthB, yearB] = b.split(' ');

          if (yearA !== yearB) {
            return +yearB - +yearA;
          }

          return monthsOfYear.indexOf(monthB.toLowerCase()) - monthsOfYear.indexOf(monthA.toLowerCase());
        });
        return archive;
      };

      $scope.getQuestions = function (pageNum) {
        questionsService.getPage(pageNum).then(function (data) {
          $scope.questions = data.questions;
          $scope.archiveList = ['ALL', ...sortArchiveDates(data.dates)];
          $scope.totalPages = data.paginate.total_pages;
        });
      };

      $scope.getQuestions();
    }
  });
