angular.module('app').directive('drPricingCard', () => {
  let link = scope => {
    scope.getInteger = value => {
      return Math.floor(value);
    };

    scope.getDecimal = value => {
      const res = Math.round((value - Math.floor(value)) * 100);
      return res ? res : '';
    };
  };

  return {
    restrict: 'E',
    templateUrl: 'app/directives/drPricingCard/drPricingCardView.html',
    link: link,
    scope: {
      data: '=',
      isAnnuallyMode: '='
    }
  };
});
