angular
  .module('app')
  .component('committeeMembersComponent', {
    templateUrl: 'app/components/committee-members/committee-members.tmpl.html',
    controller: function ($scope, dynamicContent, appConfig, headerConfig, $http) {
      $scope.dynamicContent = '';
      $scope.committees = [];
      $scope.filterList = [];
      $scope.notification = {loading: 'Loading...', notFound: ''};
      $scope.showSelectors = false;
      $scope.searchStr = '';

      dynamicContent
        .getDynamicContentByKey('committee')
        .then((content) => $scope.dynamicContent = content);

      const headersConfig = headerConfig.get();

      $scope.init = () => {
        const currentUrl = `${appConfig.dashboardServiceUrl}/api/committees.json`;

        $http.get(`${currentUrl}${$scope.searchStr.length ? '?' +  $scope.searchStr : ''}`, headersConfig)
          .then((res) => {
            $scope.committees = res.data.committees;

            const { categories = [], seasons = [], years = [], popularities = [], collections = []} = res.data;
            $scope.filterList = [categories, seasons, years, popularities, collections];
            $scope.showSelectors = true;

            if(!$scope.committees.length) {
              $scope.notification = "Not Found";
            } else {
              $scope.notification = "";
            }
          });
      };

      $scope.init();

      $scope.filterBySearch = (multiSearch) => {
        $scope.searchStr = '';

        for (item in multiSearch) {
          $scope.searchStr += `&${item}=${multiSearch[item]}`;
        }

        $scope.committees = [];
        $scope.init();
      };
    }
  })
