angular
  .module('app')
  .component('appFooter', {
    templateUrl: 'app/footer.html',
    controller: function ($scope, $state, dataValidate, $location, dynamicContent, contactSubmit) {
      var self = this;
      self.becomeAMemberText = '';
      self.isFormSending = false;

			this.data = {
				email: {value: '', required: true, name: 'email', type: ''},
			};

      this.hidePrefooter = function () {
        return $state.current.name === 'privacy' ||
               $state.current.name === 'terms' ||
               $state.current.name === 'login';
      };

      this.stayConnected = function () {
				if (!dataValidate.validate(this.data))
				  return;

				self.isFormSending = true;

        contactSubmit.send(contactSubmit.stayConnectedFormRawDataForApi(this.data))
          .then(()  => $location.path('/thank-you').search({state: 'true'}))
          .finally(() => self.isFormSending = false);
      };

      dynamicContent.getDynamicContentByKey('become-a-member').then(function (content) {
        self.becomeAMemberText = content;
      });
    }
  });
