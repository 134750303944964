angular
	.module('app')
	.component('forecastTrendStoriesByIdComponent', {
		templateUrl: 'app/components/forecast-trend-stories-by-id/forecast-trend-stories.tmpl.html',
		controller: function ($http, appConfig, $stateParams, headerConfig, $scope, $cookies, $location) {

			const vm = this;
			this.activeBioBoxTitle = '';
			this.activeBioBoxDescription = '';
			this.activeBioBoxSide = '';

			$scope.$on('saveColor', function (evt, data) {
				vm.activeBioBoxTitle = '';
			});

			vm.authorizedUser = false;

			if($cookies.get('caus_session') !== undefined) {
				vm.authorizedUser = true;
			}

			const headersConfig = headerConfig.get();

			vm.init = () => {
				$http.get(`${appConfig.dashboardServiceUrl}api/forecasts/${$stateParams.id}.json`, headersConfig)
					.then((res) => {
						vm.forecast = res.data;
						vm.categoryName = res.data.category[0].toUpperCase() + res.data.category.slice(1);
						vm.forecastTrends = res.data.forecast_trends.map((item) => {
							item.trend_colors.map((item) => {
								try {
										item.red = item.rgb.split(',')[0].trim();
										item.green = item.rgb.split(',')[1].trim();
										item.blue = item.rgb.split(',')[2].trim();
								} catch (err) {
									item.red = '';
									item.green = '';
									item.blue = '';
								}
								return item.red, item.green, item.blue;
							});
							return item;
						});
					});
			}

			vm.init();

			$(window).scroll(function () {
				if ($(this).scrollTop() > 1000) {
					$('#button-scroll-top').fadeIn();
				} else {
					$('#button-scroll-top').fadeOut();
				}
			});

			this.buttonScrollTop = function () {
				$("html, body").animate({ scrollTop: 0 }, 1000);
				return false;
			};

			$scope.redirectToForecast = () => {
				$location.path(`/forecast/${vm.forecast.id}`);
			};

			$scope.anchorLink = (str) => {
				const idElem = `#${str}-item`;
				$('html, body').animate({
					scrollTop: $(idElem).offset().top
				}, 1000);
			};

			$scope.setActiveBioBox = function (event, title, description) {
				vm.activeBioBoxTitle = title;
				vm.activeBioBoxDescription = description;

				var side = document.documentElement.clientWidth - event.clientX;

				if (side < 640) {
					vm.activeBioBoxSide = 'right';
				} else {
					vm.activeBioBoxSide = 'left;'
				}
			}
		}
	});
