angular.module('app')
	.factory('categoryValues', function ($http) {
		return function (field) {
			var categoryList = [{id: null, title: 'PLEASE SELECT A CATEGORY'}];
			$http.get(`https://colorassociationus.herokuapp.com/api/settings.json`)
				.then((res) => {
					res.data.profile_categories.forEach((item, index) => {
						categoryList.push({id: index, title: item})
					})
				})
				.catch((err) => {});
			switch (field) {
				case 'job function': {
					return [
						{id: null, title: 'PLEASE SELECT A JOB FUNCTION'},
						{id: 1, title: 'Intern'},
						{id: 2, title: 'Analyst/Associate'},
						{id: 3, title: 'Manager'},
						{id: 4, title: 'Director'},
						{id: 5, title: 'VP/SVP/EVP'},
						{id: 6, title: 'Chairman/President/C-Suite'}
					];
				}
				case 'company size': {
					return [
						{id: null, title: 'PLEASE SELECT COMPANY SIZE'},
						{id: 1, title: '1-10 employees'},
						{id: 2, title: '11-50 employees'},
						{id: 3, title: '51-200 employees'},
						{id: 4, title: '201-500 employees'},
						{id: 5, title: '501-1000 employees'},
						{id: 6, title: '1001-5000 employees'},
						{id: 7, title: '5000+ employees'}
					];
				}

				case 'membership': {
					return [
						{id: null, title: 'PLEASE SELECT A MEMBERSHIP'},
						{id: 1, title: 'Member@large'},
						{id: 2, title: 'Corporate'},
						{id: 3, title: 'Small Business'},
						{id: 4, title: 'Education'}
					];
				}
				case 'category': {
					return categoryList;
				}
				case 'industry': {
					return [
						{
							id: null,
							title: 'PLEASE SELECT INDUSTRY'
						},
						{
							id: 1,
							title: 'Activewear'
						},
						{
							id: 2,
							title: 'Auto'
						},
						{
							id: 3,
							title: 'Agency'
						},
						{
							id: 4,
							title: 'Beauty'
						},
						{
							id: 5,
							title: 'Big Box Retail'
						},
						{
							id: 6,
							title: 'Consumer Electronics'
						},
						{
							id: 7,
							title: 'CPG/FMCG'
						},
						{
							id: 8,
							title: 'Department Stores'
						},
						{
							id: 9,
							title: 'Educational Institution'
						},
						{
							id: 10,
							title: 'Fashion'
						},
						{
							id: 11,
							title: 'Financial Services'
						},
						{
							id: 12,
							title: 'Food & Beverage'
						},
						{
							id: 13,
							title: 'Hospitality'
						},
						{
							id: 14,
							title: 'Real Estate'
						},
						{
							id: 15,
							title: 'Restaurants'
						},
						{
							id: 16,
							title: 'Specialty Retail'
						},
						{
							id: 17,
							title: 'Technology'
						},
						{
							id: 18,
							title: 'Watches & Jewelry'
						},
						{
							id: 19,
							title: 'Other'
						}
					];
				}
				case 'country': {
					return [
						{
							id: 1,
							label: 'Afghanistan'
						},
						{
							id: 2,
							label: 'Albania'
						},
						{
							id: 3,
							label: 'Algeria'
						},
						{
							id: 4,
							label: 'Andorra'
						},
						{
							id: 5,
							label: 'Angola'
						},
						{
							id: 6,
							label: 'Anguilla'
						},
						{
							id: 7,
							label: 'Antigua & Barbuda'
						},
						{
							id: 8,
							label: 'Argentina'
						},
						{
							id: 9,
							label: 'Armenia'
						},
						{
							id: 10,
							label: 'Australia'
						},
						{
							id: 11,
							label: 'Austria'
						},
						{
							id: 12,
							label: 'Azerbaijan'
						},
						{
							id: 13,
							label: 'Bahamas'
						},
						{
							id: 14,
							label: 'Bahrain'
						},
						{
							id: 15,
							label: 'Bangladesh'
						},
						{
							id: 16,
							label: 'Barbados'
						},
						{
							id: 17,
							label: 'Belarus'
						},
						{
							id: 18,
							label: 'Belgium'
						},
						{
							id: 19,
							label: 'Belize'
						},
						{
							id: 20,
							label: 'Benin'
						},
						{
							id: 21,
							label: 'Bermuda'
						},
						{
							id: 22,
							label: 'Bhutan'
						},
						{
							id: 23,
							label: 'Bolivia'
						},
						{
							id: 24,
							label: 'Bosnia & Herzegovina'
						},
						{
							id: 25,
							label: 'Botswana'
						},
						{
							id: 26,
							label: 'Brazil'
						},
						{
							id: 27,
							label: 'Brunei Darussalam'
						},
						{
							id: 28,
							label: 'Bulgaria'
						},
						{
							id: 29,
							label: 'Burkina Faso'
						},
						{
							id: 30,
							label: 'Myanmar/Burma'
						},
						{
							id: 31,
							label: 'Burundi'
						},
						{
							id: 32,
							label: 'Cambodia'
						},
						{
							id: 33,
							label: 'Cameroon'
						},
						{
							id: 34,
							label: 'Canada'
						},
						{
							id: 35,
							label: 'Cape Verde'
						},
						{
							id: 36,
							label: 'Cayman Islands'
						},
						{
							id: 37,
							label: 'Central African Republic'
						},
						{
							id: 38,
							label: 'Chad'
						},
						{
							id: 39,
							label: 'Chile'
						},
						{
							id: 40,
							label: 'China'
						},
						{
							id: 41,
							label: 'Colombia'
						},
						{
							id: 42,
							label: 'Comoros'
						},
						{
							id: 43,
							label: 'Congo'
						},
						{
							id: 44,
							label: 'Costa Rica'
						},
						{
							id: 45,
							label: 'Croatia'
						},
						{
							id: 46,
							label: 'Cuba'
						},
						{
							id: 47,
							label: 'Cyprus'
						},
						{
							id: 48,
							label: 'Czech Republic'
						},
						{
							id: 49,
							label: 'Democratic Republic of the Congo'
						},
						{
							id: 50,
							label: 'Denmark'
						},
						{
							id: 51,
							label: 'Djibouti'
						},
						{
							id: 52,
							label: 'Dominica'
						},
						{
							id: 53,
							label: 'Dominican Republic'
						},
						{
							id: 54,
							label: 'Ecuador'
						},
						{
							id: 55,
							label: 'Egypt'
						},
						{
							id: 56,
							label: 'El Salvador'
						},
						{
							id: 57,
							label: 'Equatorial Guinea'
						},
						{
							id: 58,
							label: 'Eritrea'
						},
						{
							id: 59,
							label: 'Estonia'
						},
						{
							id: 60,
							label: 'Ethiopia'
						},
						{
							id: 61,
							label: 'Fiji'
						},
						{
							id: 62,
							label: 'Finland'
						},
						{
							id: 63,
							label: 'France'
						},
						{
							id: 64,
							label: 'French Guiana'
						},
						{
							id: 65,
							label: 'Gabon'
						},
						{
							id: 66,
							label: 'Gambia'
						},
						{
							id: 67,
							label: 'Georgia'
						},
						{
							id: 68,
							label: 'Germany'
						},
						{
							id: 69,
							label: 'Ghana'
						},
						{
							id: 70,
							label: 'Great Britain'
						},
						{
							id: 71,
							label: 'Greece'
						},
						{
							id: 72,
							label: 'Grenada'
						},
						{
							id: 73,
							label: 'Guadeloupe'
						},
						{
							id: 74,
							label: 'Guatemala'
						},
						{
							id: 75,
							label: 'Guinea'
						},
						{
							id: 76,
							label: 'Guinea-Bissau'
						},
						{
							id: 77,
							label: 'Guyana'
						},
						{
							id: 78,
							label: 'Haiti'
						},
						{
							id: 79,
							label: 'Honduras'
						},
						{
							id: 80,
							label: 'Hungary'
						},
						{
							id: 81,
							label: 'Iceland'
						},
						{
							id: 82,
							label: 'India'
						},
						{
							id: 83,
							label: 'Indonesia'
						},
						{
							id: 84,
							label: 'Iran'
						},
						{
							id: 85,
							label: 'Iraq'
						},
						{
							id: 86,
							label: 'Israel and the Occupied Territories'
						},
						{
							id: 87,
							label: 'Italy'
						},
						{
							id: 88,
							label: 'Ivory Coast (Cote d`Ivoire)'
						},
						{
							id: 89,
							label: 'Jamaica'
						},
						{
							id: 90,
							label: 'Japan'
						},
						{
							id: 91,
							label: 'Jordan'
						},
						{
							id: 92,
							label: 'Kazakhstan'
						},
						{
							id: 93,
							label: 'Kenya'
						},
						{
							id: 94,
							label: 'Kosovo'
						},
						{
							id: 95,
							label: 'Kuwait'
						},
						{
							id: 96,
							label: 'Kyrgyz Republic (Kyrgyzstan)'
						},
						{
							id: 97,
							label: 'Laos'
						},
						{
							id: 98,
							label: 'Latvia'
						},
						{
							id: 99,
							label: 'Lebanon'
						},
						{
							id: 100,
							label: 'Lesotho'
						},
						{
							id: 101,
							label: 'Liberia'
						},
						{
							id: 102,
							label: 'Libya'
						},
						{
							id: 103,
							label: 'Liechtenstein'
						},
						{
							id: 104,
							label: 'Lithuania'
						},
						{
							id: 105,
							label: 'Luxembourg'
						},
						{
							id: 106,
							label: 'Republic of Macedonia'
						},
						{
							id: 107,
							label: 'Madagascar'
						},
						{
							id: 108,
							label: 'Malawi'
						},
						{
							id: 109,
							label: 'Malaysia'
						},
						{
							id: 110,
							label: 'Maldives'
						},
						{
							id: 111,
							label: 'Mali'
						},
						{
							id: 112,
							label: 'Malta'
						},
						{
							id: 113,
							label: 'Martinique'
						},
						{
							id: 114,
							label: 'Mauritania'
						},
						{
							id: 115,
							label: 'Mauritius'
						},
						{
							id: 116,
							label: 'Mayotte'
						},
						{
							id: 117,
							label: 'Mexico'
						},
						{
							id: 118,
							label: 'Moldova, Republic of'
						},
						{
							id: 119,
							label: 'Monaco'
						},
						{
							id: 120,
							label: 'Mongolia'
						},
						{
							id: 121,
							label: 'Montenegro'
						},
						{
							id: 122,
							label: 'Montserrat'
						},
						{
							id: 123,
							label: 'Morocco'
						},
						{
							id: 124,
							label: 'Mozambique'
						},
						{
							id: 125,
							label: 'Namibia'
						},
						{
							id: 126,
							label: 'Nepal'
						},
						{
							id: 127,
							label: 'Netherlands'
						},
						{
							id: 128,
							label: 'New Zealand'
						},
						{
							id: 129,
							label: 'Nicaragua'
						},
						{
							id: 130,
							label: 'Niger'
						},
						{
							id: 131,
							label: 'Nigeria'
						},
						{
							id: 132,
							label: 'Korea, Democratic Republic of (North Korea)'
						},
						{
							id: 133,
							label: 'Norway'
						},
						{
							id: 134,
							label: 'Oman'
						},
						{
							id: 135,
							label: 'Pacific Islands'
						},
						{
							id: 136,
							label: 'Pakistan'
						},
						{
							id: 137,
							label: 'Panama'
						},
						{
							id: 138,
							label: 'Papua New Guinea'
						},
						{
							id: 139,
							label: 'Paraguay'
						},
						{
							id: 140,
							label: 'Peru'
						},
						{
							id: 141,
							label: 'Philippines'
						},
						{
							id: 142,
							label: 'Poland'
						},
						{
							id: 143,
							label: 'Portugal'
						},
						{
							id: 144,
							label: 'Puerto Rico'
						},
						{
							id: 145,
							label: 'Qatar'
						},
						{
							id: 146,
							label: 'Reunion'
						},
						{
							id: 147,
							label: 'Romania'
						},
						{
							id: 148,
							label: 'Russian Federation'
						},
						{
							id: 149,
							label: 'Rwanda'
						},
						{
							id: 150,
							label: 'Saint Kitts and Nevis'
						},
						{
							id: 151,
							label: 'Saint Lucia'
						},
						{
							id: 152,
							label: 'Saint Vincent`s & Grenadines'
						},
						{
							id: 153,
							label: 'Samoa'
						},
						{
							id: 154,
							label: 'Sao Tome and Principe'
						},
						{
							id: 155,
							label: 'Saudi Arabia'
						},
						{
							id: 156,
							label: 'Senegal'
						},
						{
							id: 157,
							label: 'Serbia'
						},
						{
							id: 158,
							label: 'Seychelles'
						},
						{
							id: 159,
							label: 'Sierra Leone'
						},
						{
							id: 160,
							label: 'Singapore'
						},
						{
							id: 161,
							label: 'Slovak Republic (Slovakia)'
						},
						{
							id: 162,
							label: 'Slovenia'
						},
						{
							id: 163,
							label: 'Solomon Islands'
						},
						{
							id: 164,
							label: 'Somalia'
						},
						{
							id: 165,
							label: 'South Africa'
						},
						{
							id: 166,
							label: 'Korea, Republic of (South Korea)'
						},
						{
							id: 167,
							label: 'South Sudan'
						},
						{
							id: 168,
							label: 'Spain'
						},
						{
							id: 169,
							label: 'Sri Lanka'
						},
						{
							id: 170,
							label: 'Sudan'
						},
						{
							id: 171,
							label: 'Suriname'
						},
						{
							id: 172,
							label: 'Swaziland'
						},
						{
							id: 173,
							label: 'Sweden'
						},
						{
							id: 174,
							label: 'Switzerland'
						},
						{
							id: 175,
							label: 'Syria'
						},
						{
							id: 176,
							label: 'Tajikistan'
						},
						{
							id: 177,
							label: 'Tanzania'
						},
						{
							id: 178,
							label: 'Thailand'
						},
						{
							id: 179,
							label: 'Timor Leste'
						},
						{
							id: 180,
							label: 'Togo'
						},
						{
							id: 181,
							label: 'Trinidad & Tobago'
						},
						{
							id: 182,
							label: 'Tunisia'
						},
						{
							id: 183,
							label: 'Turkey'
						},
						{
							id: 184,
							label: 'Turkmenistan'
						},
						{
							id: 185,
							label: 'Turks & Caicos Islands'
						},
						{
							id: 186,
							label: 'Uganda'
						},
						{
							id: 187,
							label: 'Ukraine'
						},
						{
							id: 188,
							label: 'United Arab Emirates'
						},
						{
							id: 189,
							label: 'United States of America (USA)'
						},
						{
							id: 190,
							label: 'Uruguay'
						},
						{
							id: 191,
							label: 'Uzbekistan'
						},
						{
							id: 192,
							label: 'Venezuela'
						},
						{
							id: 193,
							label: 'Vietnam'
						},
						{
							id: 194,
							label: 'Virgin Islands (UK)'
						},
						{
							id: 195,
							label: 'Virgin Islands (US)'
						},
						{
							id: 196,
							label: 'Yemen'
						},
						{
							id: 197,
							label: 'Zambia'
						},
						{
							id: 198,
							label: 'Zimbabwe'
						}
					];
				}
				case 'states': {
					return [
						{id: 0, title: 'STATE/PROVINCE'},
						{id: 1, title: 'Alabama'},
						{id: 2, title: 'Alabama'},
						{id: 3, title: 'American Samoa'},
						{id: 4, title: 'Arizona'},
						{id: 5, title: 'Arkansas'},
						{id: 6, title: 'California'},
						{id: 7, title: 'Colorado'},
						{id: 8, title: 'Connecticut'},
						{id: 9, title: 'Delaware'},
						{id: 10, title: 'District of Columbia'},
						{id: 11, title: 'Florida'},
						{id: 12, title: 'Georgia'},
						{id: 13, title: 'Guam'},
						{id: 14, title: 'Hawaii'},
						{id: 15, title: 'Idaho'},
						{id: 16, title: 'Illinois'},
						{id: 17, title: 'Indiana'},
						{id: 18, title: 'Iowa'},
						{id: 19, title: 'Kansas'},
						{id: 20, title: 'Kentucky'},
						{id: 21, title: 'Louisiana'},
						{id: 22, title: 'Maine'},
						{id: 23, title: 'Maryland'},
						{id: 24, title: 'Massachusetts'},
						{id: 25, title: 'Michigan'},
						{id: 26, title: 'Minnesota'},
						{id: 27, title: 'Mississippi'},
						{id: 28, title: 'Missouri'},
						{id: 29, title: 'Montana'},
						{id: 30, title: 'Nebraska'},
						{id: 31, title: 'Nevada'},
						{id: 32, title: 'New Hampshire'},
						{id: 33, title: 'New Jersey'},
						{id: 34, title: 'New Mexico'},
						{id: 35, title: 'New York'},
						{id: 36, title: 'North Carolina'},
						{id: 37, title: 'North Dakota'},
						{id: 38, title: 'Northern Mariana Islands'},
						{id: 39, title: 'Ohio'},
						{id: 40, title: 'Oklahoma'},
						{id: 41, title: 'Oregon'},
						{id: 42, title: 'Pennsylvania'},
						{id: 43, title: 'Puerto Rico'},
						{id: 44, title: 'Rhode Island'},
						{id: 45, title: 'South Carolina'},
						{id: 46, title: 'South Dakota'},
						{id: 47, title: 'Tennessee'},
						{id: 48, title: 'Texas'},
						{id: 49, title: 'United States Minor Outlying Islands'},
						{id: 50, title: 'Utah'},
						{id: 51, title: 'Vermont'},
						{id: 52, title: 'Virgin Islands'},
						{id: 53, title: 'Virginia'},
						{id: 54, title: 'Washington'},
						{id: 55, title: 'West Virginia'},
						{id: 56, title: 'Wisconsin'},
						{id: 57, title: 'Wyoming'}
					];
				}
				default: {
					return [];
				}
			}
		};
	});
