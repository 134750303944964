angular
	.module('app')
	.component('colorReportsComponent', {
		templateUrl: 'app/components/color-reports/color-reports.tmpl.html',
		controller: function ($scope, appConfig, $http, $cookies) {
			const vm = this;

			vm.authorizedUser = false;

			if($cookies.get('caus_session') !== undefined) {
				vm.authorizedUser = true;
			}

			let perPage = 8,
				numPage = 1;

			$scope.reports = [];
			$scope.filterList = [];
			$scope.showSelectors = false;
			$scope.notification = "";
			$scope.searchStr = '';

			$scope.init = () => {
				const currentUrl = `${appConfig.dashboardServiceUrl}api/reports.json?page=${numPage}&per_page=${perPage}`;
				vm.loading = true;
				$scope.notification= "";
				$http.get(`${currentUrl}${$scope.searchStr.length ? $scope.searchStr : ''}`)
				.then((res) => {
					const { reports } = res.data;
					vm.total_pages = res.data.paginate.total_pages;

					if(!$scope.filterList.length) {
						const { categories, seasons, years, popularity = [], collections = [] } = res.data;
						$scope.filterList = [categories, seasons, years, popularity, collections];
						$scope.showSelectors = true;
					}

					if(!reports.length && !$scope.reports.length) {
						$scope.notification = "Not Found";
						vm.loading = false;
					} else {
						$scope.notification = "";
						vm.loading = false;
					}

					$scope.reports = [...$scope.reports, ...reports];
				});
			}

			$scope.init();

			$scope.filterBySearch = (multiSearch) => {
				$scope.searchStr = '';
				vm.total_pages = 0;

				for (item in multiSearch) {
					$scope.searchStr += `&${item}=${multiSearch[item]}`;
				}

				numPage = 1;
				$scope.reports = [];
				$scope.init();
			}

			this.showMoreItems = () => {
				numPage += 1;
				$scope.init();
			};

			this.hasMoreReportsToShow = () => numPage < vm.total_pages && vm.loading !== true;
		}
	});
