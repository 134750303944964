angular
	.module('app')
	.component('reportComponent', {
		templateUrl: 'app/components/report/report.tmpl.html',
		controller: function ($http, appConfig, $stateParams, headerConfig, $cookies, $window) {
			var vm = this;
			vm.user = $cookies.get('caus_session');
			const headersConfig = headerConfig.get();

			vm.init = function () {
				$http.get(`${appConfig.dashboardServiceUrl}api/reports/${$stateParams.id}.json`, headersConfig)
					.then(function (res) {
						vm.report = res.data;
					});
			};

			vm.visitStore = function () {
				$window.open(vm.report.store_url, '_blank');
			}

		}
	});
