angular.module('app').service('contactSubmit', function (appConfig, $http) {
  function stayConnectedFormRawDataForApi (rawData) {
    return {
      contact: {
        email: rawData.email.value,
        origin: 'stay-connected'
      }
    };
  }

  function contactFormRawDataForApi (rawData) {
    return {
      contact: {
        first_name: rawData.first_name.value,
        last_name: rawData.last_name.value,
        email: rawData.email.value,
        company: rawData.company.value,
        phone: rawData.phone.value,
        title: rawData.title.value,
        comments: rawData.comments.value,
        origin: 'contact-us'
      }
    }
  }

  function pressFormRawDataForApi (rawData) {
    return {
      contact: {
        first_name: rawData.first_name.value,
        last_name: rawData.last_name.value,
        email: rawData.email.value,
        comments: rawData.comments.value,
        origin: 'press'
      }
    }
  }

  function membershipFormRawDataForApi (rawData) {
    return {
      contact: {
        first_name: rawData.first_name.value,
        last_name: rawData.last_name.value,
        email: rawData.email.value,
        company: rawData.company.value,
        origin: 'membership'
      }
    }
  }

  function send (formData) {
    return $http.post(`${appConfig.dashboardServiceUrl}api/home/contact.json`, formData);
  }

  return {
    stayConnectedFormRawDataForApi: stayConnectedFormRawDataForApi,
    contactFormRawDataForApi: contactFormRawDataForApi,
    pressFormRawDataForApi: pressFormRawDataForApi,
    membershipFormRawDataForApi: membershipFormRawDataForApi,
    send: send
  }

});
