angular
	.module('app')
	.component('cartThankComponent', {
		templateUrl: 'app/components/cart-thank/cart-thank.tmpl.html',
		controller: function ($state, $http) {

            this.orderId = 11;

            this.products = [
                {
                    image: "../../../assets/images/cart-file-img.jpg",
                    file: '#'
                },
                {
                    image: "../../../assets/images/cart-file-img.jpg",
                    file: '#'
                }
                // {
                //     image: "../../../assets/images/cart-file-img.jpg",
                //     file: '#'
                // },
                // {
                //     image: "../../../assets/images/cart-file-img.jpg",
                //     file: '#'
                // },
                // {
                //     image: "../../../assets/images/cart-file-img.jpg",
                //     file: '#'
                // }
            ];

            $(document).ready(function(){
                var windowWidth = $(window).width(),
                    quantityItems = $('.download-item').length;
                if ((windowWidth < 1200 && quantityItems > 3) || (windowWidth > 1200 && quantityItems > 4)) {
                    $('.download-row').addClass('download-row_fix-flex');
                }
            });
		}
	})
