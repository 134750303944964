angular.module('app').directive('drCollapsableTextView', () => {
  let link = scope => {
    scope.showAnswer = false;
    scope.toggleShowAnswer = () => {
      scope.showAnswer = !scope.showAnswer;
    };
  };

  return {
    restrict: 'E',
    templateUrl: 'app/directives/drCollapsableTextView/drCollapsableTextView.html',
    link: link,
    scope: {
      question: '=',
      answer: '='
    }
  };
});
