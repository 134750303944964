angular
	.module('app')
	.component('profileComponent', {
		templateUrl: 'app/components/profile/profile.tmpl.html',
		controller: function ($scope, $state, $http, $cookies, categoryValues, modalService) {
			var vm = this;
			vm.user = null;
			vm.userEdit = null;
			vm.editFlag = false;
			vm.countries = categoryValues('country');
			vm.categories = []

			$scope.uploadFiles = function (file) {
				if (file !== null && file.$ngfBlobUrl) {
					vm.userEdit.image = file.$ngfBlobUrl;
				}
			};

			$http({
				method: 'GET',
				url: 'https://colorassociationus.herokuapp.com/api/profile.json',
				headers: {
					'Authorization': 'Token token=' + $cookies.get('caus_session')
				}
			}).then(function (res) {
				if (res && res.data.profile) {
					if (res.data.profile.length !== 0) {
						vm.user = res.data.profile;
						if (vm.user.membership === null) {
							vm.user.membership = "";
						}
						if (vm.user.country.length === 0) {
							vm.user.country = "PLEASE SELECT COUNTRY";
						}
					} else {
						modalService.showModal(6);

					}
				}
			}).catch(function (err) {});

			$scope.updateSelectedOptions = function (oldValue, newValue) {

				if (newValue) {
					modalService.showModal(5);
				}

				vm.userEdit.membership = "";
				vm.userEdit.categories = "";
			};

			vm.editProfile = function () {
				vm.userEdit = Object.assign({}, vm.user);

				$http.get(`https://colorassociationus.herokuapp.com/api/settings.json`)
					.then((res) => {
						res.data.profile_categories.forEach((item, index) => {
							vm.categories.push({id: index, label: item})
						})
					})

					.catch((err) => {});


				vm.editFlag = !vm.editFlag;
			};

			vm.save = function () {
				$http({
					method: 'PUT',
					url: 'https://colorassociationus.herokuapp.com/api/profile.json',
					headers: {
						'Authorization': 'Token token=' + $cookies.get('caus_session'),
						'Content-Type': 'application/json'
					},
					data: {user: vm.userEdit}
				}).then(function (res) {
					if (res && res.data.profile) {
						if (res.data.profile.length !== 0) {
							vm.user = res.data.profile;
							vm.editFlag = !vm.editFlag;
							vm.userEdit = null;
							modalService.showModal(7);
						} else {
							modalService.showModal(6);
						}
					}
				}).catch(function (err) {
					modalService.showModal(0, {profileInfo: err.data});
				});
			};

			vm.cancel = function () {
				vm.userEdit = null;
				vm.editFlag = !vm.editFlag;
			};
		}
	});
