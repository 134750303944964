angular
  .module('app')
  .component('siteFeaturesComponent', {
    templateUrl: 'app/components/site-features/site-features.tmpl.html',
    controller: function ($sce, siteFeatures, dynamicContent, modalService) {
      this.dynamicContent = '';
      this.tabs = [];
      this.features = [];
      this.selectedTab = this.tabs[0];

      dynamicContent
        .getDynamicContentByKey('site_features')
        .then((content) => this.dynamicContent = content);

      siteFeatures.fetchAll().then(data => {
        this.features = data.site_features;
        this.tabs = this.features.map((i) => ({ id: i.id, title: i.title }));
        this.selectTab(this.tabs[0]);
      });

      this.isTabSelected = (tab) => tab.id === this.selectedTab.id;
      this.selectTab = (tab) => {
        this.selectedTab = tab;
        this.selectedFeature = this.features.find(f => f.id === this.selectedTab.id);
        this.htmlString = $sce.trustAsHtml(this.selectedFeature.description);
      };

      this.showSlideModal = (slideURL) => {
        modalService.showModal(10, {
          image_url: slideURL,
          isBook: true
        });
      }
    }
  });
