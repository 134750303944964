angular.module('app').directive('drCartBillingInfo', function () {
	function process($scope) {
		$scope.stateOfCartProgress = function () {
			$scope.step.value = 3;
		}
    }
    return {
		restrict: 'E',
			templateUrl: 'app/directives/drCartBillingInfo/drCartBillingInfoView.html',
			process: process,
			scope: false
	};
});
