angular.module('app').directive('drPreloader', function () {
	function link($scope) {

	}

	return {
		restrict: 'E',
		templateUrl: 'app/directives/drPreloader/drPreloaderView.html',
		link: link
	};
});
