angular
  .module('app')
  .service('colorNamesService', function (appConfig, $http, headerConfig) {
    this.getAll = function () {
      return $http.get(`${appConfig.dashboardServiceUrl}api/color_names.json`, headerConfig.get())
        .then(res => res.data.color_names);
    };

    this.getColorForecastsNames = function (query) {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/color_names.json`,
        method: 'GET',
        params: {query: query.toLowerCase()}
      })
        .then(function (res) {
          const forecasts = [];
          res.data.exact_matches.map(function (item) {

            let forecastId = null;

            try {
              forecastId = item.archive_forecasts[0].id
            } catch (err) {
            }

            forecasts.push({
              season: item.season,
              category: item.category,
              year: item.year,
              forecastId
            });
          });
          return forecasts;
        });
    };

    this.getByQuery = function (query, perPage,  sortAlph = false, page = 1) {
      return $http({
        url: `${appConfig.dashboardServiceUrl}api/color_names.json`,
        method: 'GET',
        params: {
          query: query.toLowerCase(),
          per_page: perPage,
          sort: sortAlph ? 'abc' : null,
          page
        }
      });
    }

  });
