angular
	.module('app')
	.component('archiveComponent', {
		templateUrl: 'app/components/archive/archive.tmpl.html',
		controller: function ($scope) {
			var vm = this;
			vm.checkedCheckboxes = [];

			$scope.colors = [
				{	name: "color name",
					value: '#ada298',
					done: false
				},
				{	name: "color name",
					value: '#ada298',
					done: false
				},
				{	name: "color name",
					value: '#ada298',
					done: false
				},
				{	name: "color name",
					value: '#ada298',
					done: false
				},
				{	name: "color name",
					value: '#ada298',
					done: false
				},
				{	name: "color name",
					value: '#ada298',
					done: false
				},
				{	name: "color name",
					value: '#d18db5',
					done: false
				},
				{	name: "color name",
					value: '#ada298',
					done: false
				},
				{	name: "color name",
					value: '#ada298',
					done: false
				},
				{	name: "color name",
					value: '#aba289',
					done: false
				},
				{	name: "color name",
					value: '#bf9f7f',
					done: false
				},
				{	name: "color name",
					value: '#ada298',
					done: false
				},
				{	name: "color name",
					value: '#ada298',
					done: false
				},
				{	name: "color name",
					value: '#ada298',
					done: false
				},
				{	name: "color name",
					value: '#ada298',
					done: false
				}
			];

			$scope.colorsInclude = [
				{
					description: 'Cable No. 65001 - U.S. Army Golden Yellow'
				},
				{
				 description: 'Cable No. 65002 - U.S. Army Yellow'
				},
				{
					description: 'Cable No. 65003 - U.S. Army Golden Orange'
				},
				{
					description: 'Cable No. 65004 - U.S. Army Orange'
				},
				{
					description: 'Cable No. 65005 - U.S. Army White'
				},
				{
					description: 'Cable No. 65006 - U.S. Army Scarlet'
				},
				{
					description: 'Cable No. 65007 - U.S. Army Green'
				},
				{
					description: 'Cable No. 65008 - U.S. Army Silver Gray'
				},
				{
					description: 'Cable No. 65009 - U.S. Army Pansy'
				},
				{
					description: 'Cable No. 65010 - U.S. Army Blue'
				},
				{
					description: 'Cable No. 65011 - U.S. Army Cobalt Bue'
				}
			];

			this.checkedArchiveCheckbox = function (color) {
				if (color.done) {
					vm.checkedCheckboxes.push(color);
				} else {
					vm.checkedCheckboxes.splice(vm.checkedCheckboxes.indexOf(color), 1);
				}
			}
		}
	});
