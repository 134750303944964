angular
	.module('app')
	.component('termsComponent', {
		templateUrl: 'app/components/terms/terms.tmpl.html',
		controller: function ($state, dynamicContent) {
			var self = this;

			dynamicContent.getDynamicContentByKey('terms').then(function (content) {
				self.termsText = content;
			});
		}
	});
