angular.module('app')
	.service('authService', ['$http', '$cookies', '$window', 'appConfig', '$state', 'localStorageService',
		function ($http, $cookies, $window, appConfig, $state, localStorageService) {
			var self = this;
			this.token = $cookies.get('caus_session');

			this.login = function (username, password, isRemembered) {
				return $http.post(appConfig.dashboardServiceUrl + 'api/sessions.json', {
					email: username,
					password: password
				}).then(function (res) {
					if (res.data && res.data.profile.token) {
						self.setToken(res.data.profile.token, isRemembered);
						self.currentUser = res.data.profile.first_name;
						self.isAdmin = res.data.profile.email === 'lhcolor@mac.com'
						self.token = res.data.profile.token;
						localStorageService.set('currentUser', self.currentUser);
						localStorageService.set('isAdmin', self.isAdmin);
					}
					return res.data;
				}).catch(function (err) {});
			};

      this.universityLogin = function () {
				return $http.get(appConfig.dashboardServiceUrl + 'api/profile.json', {}).then(function (res) {
					if (res.data && res.data.profile.token) {
						self.setToken(res.data.profile.token, true);
						self.currentUser = res.data.profile.first_name;
						self.isAdmin = false
						self.token = res.data.profile.token;
						localStorageService.set('currentUser', self.currentUser);
						localStorageService.set('isAdmin', self.isAdmin);
					}
					return res.data;
				}).catch(function (err) {});
			};

			this.logOut = function () {
				$cookies.remove('caus_session');
				localStorageService.remove('currentUser');
				localStorageService.remove('isAdmin', self.isAdmin);
				$state.go('/');
			};

			this.loadCurrentUser = function () {
				return $http.get(appConfig.dashboardServiceUrl + 'profile.json',
					{params: {token: self.token}})
					.then(function (data) {
						if (data.data.success) {
							self.currentUser = data.data.user;
							localStorageService.set('currentUser', self.currentUser);
						} else {
							localStorageService.set('currentUser', {});
						}
						return data;
					});
			};

			this.getCurrentUser = function () {
				return self.currentUser || {};
			};

			this.setToken = function (token, isRemembered) {
				var date = new Date();
				if (isRemembered) {
					date.setFullYear(date.getFullYear() + 1);
				} else {
					date.setDate(date.getDate() + 1);
				}
				$cookies.put('caus_session', token, {expires: date});
				this.token = token;
			};
		}]);
