angular
	.module('app')
	.component('loginComponent', {
		templateUrl: 'app/components/login/login.tmpl.html',
		controller: function ($state, $http, authService, dataValidate) {
			var self = this;
			this.email = '';
			this.password = '';
			this.isRemembered = false;
			this.error = '';

			this.viewMode = 'login'; // login | forgot | resetRequired
			this.emailForReset = {value: '', name: 'email', required: true, type: 'email'};

			this.login = function () {
				self.error = false;
				authService.login(this.email, this.password, this.isRemembered)
					.then(function (data) {
						if (data && data.profile.token) {
							$state.go('/');
						} else {
							self.error = true;
						}
					});
			};


			this.resetPassword = function () {
				const { emailForReset } = self;
				const isDataValid = dataValidate.validate({ emailForReset });
				if (isDataValid) {
					self.changeViewMode('resetRequired');
					self.emailForReset.value = '';
				}
			};

			this.changeViewMode = function (v) {
				self.viewMode = v;
			};
		}
	});
