angular
  .module('app')
  .component('questionPageComponent', {
    templateUrl: 'app/components/questionPage/questionPage.tmpl.html',
    controller: function ($scope, $sce, $stateParams, questionsService, modalService) {
      $scope.otherQuestions = [];
      let imgSrc = '';

      questionsService.getQuestion($stateParams.id).then(function (data) {
        $scope.questionData = data.data;
        $scope.htmlString = $sce.trustAsHtml($scope.questionData.body);
        imgSrc = $scope.questionData.home_page_image_url;
      });

      $scope.showImagePopup = function () {
        modalService.showModal(10, {
          image_url: $scope.questionData.home_page_image_url
        });
      }

    }
  });
