angular.module('app').directive('colorBioBoxModal', function (colorNamesService) {
  return {
    restrict: 'E',
    templateUrl: 'app/directives/drColorBioBoxModal/drColorBioBoxModalView.html',
    scope: {
      boxTitle: '=',
      year: '=',
      rgb: '=',
      category: '=',
      season: '=',
      id: '=',
      side: '=',
      description: '='
    },
    link: function (scope, el) {
      $(el).find('.trans-layer').on('click', function() {
        scope.hidden = true;
        scope.$apply();
      });
      scope.saveColor = function () {
        scope.$emit('saveColor', this.title);
      };

      colorNamesService.getColorForecastsNames(scope.boxTitle.trim()).then(function (forecasts) {
        const sortOrder = {
          season: {
            'fw': 1,
            'ss': 2,
            '': 3
          },
          category: {
            'men': 1,
            'women': 2,
            'youth': 3,
            'interion': 4
          }
        };

        scope.archiveForecasts = forecasts;

        scope.archiveForecasts.sort(function (a, b) {
          if (a.year !== b.year) {
            return b.year - a.year;
          } else if (a.category.toLocaleLowerCase() !== b.category.toLocaleLowerCase()) {
            return sortOrder[b.category.toLocaleLowerCase()] - sortOrder[a.category.toLocaleLowerCase()];
          } else {
            return sortOrder[b.season.toLocaleLowerCase()] - sortOrder[a.season.toLocaleLowerCase()];
          }
        });
      });
    }
  }
});
