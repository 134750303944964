angular
	.module('app')
	.component('appHeader', {
		templateUrl: 'app/header.html',
		controller: function ($scope, $http, modalService, localStorageService, $cookies, authService, appConfig, headerConfig) {
			this.show = true;
			this.isHamburgerOpenned = false;

			this.toggleMenu = (flag = null) => {
				if (flag === null) {
					return this.isHamburgerOpenned = !this.isHamburgerOpenned;
				}
				this.isHamburgerOpenned = flag;
			};

			this.userIsLoggedIn = function () {
				this.username = localStorageService.get('currentUser');
				this.isAdmin = localStorageService.get('isAdmin');
				return $cookies.get('caus_session');
			};

			const headersConfig = headerConfig.get();

			$http.get(`${appConfig.dashboardServiceUrl}api/home/forecast_menu.json`)
				.then((res) => {
					if(res.data) {
						this.forecastsMenuList = res.data;
						this.forecastsExternalMenuList = [];

						for (externalForecast in this.forecastsMenuList) {
							this.forecastsExternalMenuList.push(Object.keys(this.forecastsMenuList[externalForecast])[0]);
							this.forecastsMenuList.push()
						}
					}
				});

			this.logOut = function () {
				authService.logOut();
			};
		}
	});
