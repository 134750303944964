angular.module('app')
	.service('headerConfig',['$cookies', function ($cookies) {
        const userToken = $cookies.get('caus_session');
        const headerConfig = {
            headers: {
                "Authorization": `Token token=${userToken}`
            }
        }

		function get() {
			return headerConfig;
		}

		return {
			get: get
		};
	}]);
