angular
	.module('app')
	.component('myColorsComponent', {
		templateUrl: 'app/components/my-colors/my-colors.tmpl.html',
		controller: function ($scope, $state, $http, serviceItem, $cookies, modalService, appConfig, ngNotify) {
			var vm = this;
			$scope.notification = {warning: ' ', error: false};
			vm.myColors = [];
			vm.myColorsTemp = [];

			$http({
				method: 'GET',
				url: 'https://colorassociationus.herokuapp.com/api/profile.json',
				headers: {
					'Authorization': 'Token token=' + $cookies.get('caus_session')
				}
			}).then(function (res) {
				if (res && res.data.color_names) {
					if (res.data.color_names.length === 0) {
						$scope.notification = {warning: 'You don\'t have colors', error: true};
					} else {
						vm.myColors = res.data.color_names;
						vm.myColorsTemp = vm.myColors;
					}
				}
			}).catch(function (err) {
				$scope.notification = {warning: 'You don\'t have colors', error: true};
				if (err.status === 401) {
					modalService.showModal(6);
				}
			});

			this.deleteColor = function (color) {
				vm.myColors.splice(vm.myColors.indexOf(color), 1);
				if (vm.myColors.length === 0) {
					$scope.notification = {warning: 'You don\'t have colors', error: true};
				}

				$http({
					method: 'DELETE',
					url: 'https://colorassociationus.herokuapp.com/api/profile/color_names/remove.json',
					headers: {
						'Authorization': 'Token token=' + $cookies.get('caus_session'),
						'Content-Type': 'application/json'
					},
					data: {'color_name_ids': [color.id]}
				}).then((res) => {
					if (res.data.success === true) {
						ngNotify.set('You have removing the color!', {
							type: 'success',
							duration: 2000
						});
					}
				})
					.catch((err) => {});
			};

			$scope.current = {
				category: 'SORT'
			};

			$scope.categories = ['All', 'Date', 'Color Family', 'Color Name', 'Forecast'];

			$scope.showSelectCategory = function () {
				$('.selectPerPage__list-category').toggleClass('show');
			};

			$scope.clickSelectCategory = function (sort) {
				$('.selectPerPage__list-category').toggleClass('show');
				$scope.notification.warning = '';
				vm.myColorsTemp = [...vm.myColors];
				switch (sort) {
					case 'All':
						return vm.myColorsTemp;
						break;
					case 'Color Name':
						vm.myColorsTemp.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));
						break;
					case 'Date':
						vm.myColorsTemp.sort((a, b) => (a.year > b.year) ? 1 : ((b.year > a.year) ? -1 : 0));
						break;
					case 'Color Family':
						vm.myColorsTemp.sort((a, b) => (a.family > b.family) ? 1 : ((b.family > a.family) ? -1 : 0));
						break;
					default:
						return;
				}
			};
		}
	});
