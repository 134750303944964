angular
  .module('app')
  .component('resetPass', {
    templateUrl: 'app/components/reset-pass/reset-pass.tmpl.html',
    controller: function ($location) {
      this.password = ''
      this.passwordConfirm = '';
      this.errorMessage = '';

      this.submit = () => {
        if (this.password.length < 5) {
          return this.errorMessage = 'Password must contain at least 5 symbols!';
        }
        if (this.password !== this.passwordConfirm) {
          return this.errorMessage = 'Passwords do not match! Try again.';
        }
        $location.path('/login');
      }
    }
  });
