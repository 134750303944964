angular
	.module('app')
	.component('pressComponent', {
		templateUrl: 'app/components/press/press.tmpl.html',
		controller: function ($http, dataValidate, appConfig, $scope, $location, contactSubmit) {
			$scope.presses = [];
			$scope.isFormSending = false;

			this.data = {
				first_name: {value: '', required: true, name: 'first name', type: 'provide'},
				last_name: {value: '', required: true, name: 'last name', type: 'provide'},
				email: {value: '', required: true, name: 'email', type: ''},
				comments: {value: '', required: false, name: 'comments', type: ''}
			};

			this.inquiryPress = function () {
				if (dataValidate.validate(this.data)) {
					$scope.isFormSending = true;
					contactSubmit.send(contactSubmit.pressFormRawDataForApi(this.data))
						.then(() => $location.path('/thank-you'))
						.finally(() => $scope.isFormSending = false);
				}
			};

			$http.get(`${appConfig.dashboardServiceUrl}api/presses.json`)
				.then(function (res) {
					if(res && res.data) {
						res.data.presses.sort(function (a, b) {
							return new Date(b.published_at) - new Date(a.published_at);
						});
						$scope.presses = [...res.data.presses];
					}
				});
		}
	});
